var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "fd-scheduler-screen",
      attrs: { fluid: "", "data-cy": "workorderscheduler" }
    },
    [
      !_vm.currentUserCanViewWorkOrderSchedule
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("scheduler.no-permission-to-view")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("scheduler.list-title")) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.contractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background fd-headerbar-filters"
            },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "primary",
                    content: _vm.newAssignedWorkOrdersCount
                  }
                },
                [
                  _c("v-switch", {
                    staticClass: "pr-11",
                    attrs: {
                      label: _vm.$t("scheduler.list-selection-type-assigned")
                    },
                    model: {
                      value: _vm.includeAssigned,
                      callback: function($$v) {
                        _vm.includeAssigned = $$v
                      },
                      expression: "includeAssigned"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "primary",
                    content: _vm.newUnassignedWorkOrdersCount
                  }
                },
                [
                  _c("v-switch", {
                    staticClass: "pr-11",
                    attrs: {
                      label: _vm.$t("scheduler.list-selection-type-unassigned")
                    },
                    model: {
                      value: _vm.includeUnassigned,
                      callback: function($$v) {
                        _vm.includeUnassigned = $$v
                      },
                      expression: "includeUnassigned"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-switch", {
                staticClass: "pr-3",
                attrs: {
                  "data-cy": "mineswitch",
                  label: _vm.$t("scheduler.list-selection-type-mine")
                },
                model: {
                  value: _vm.requestorFilterIsMine,
                  callback: function($$v) {
                    _vm.requestorFilterIsMine = $$v
                  },
                  expression: "requestorFilterIsMine"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canFilterRequestType
            ? _c(
                "v-row",
                {
                  staticClass:
                    "ml-0 mr-0 fd-secondary-context-qualifier-background",
                  attrs: { align: "center", justify: "end" }
                },
                [
                  _vm.canFilterRequestType
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "scaffoldSwitch",
                          label: _vm.$t("scaffold-requests.types.scaffold")
                        },
                        model: {
                          value: _vm.showScaffoldRequests,
                          callback: function($$v) {
                            _vm.showScaffoldRequests = $$v
                          },
                          expression: "showScaffoldRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewMaintenanceJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "maintenanceSwitch",
                          label: _vm.$t("scaffold-requests.types.maintenance")
                        },
                        model: {
                          value: _vm.showMaintenanceRequests,
                          callback: function($$v) {
                            _vm.showMaintenanceRequests = $$v
                          },
                          expression: "showMaintenanceRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewPaintJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "paintSwitch",
                          label: _vm.$t("scaffold-requests.types.paint")
                        },
                        model: {
                          value: _vm.showPaintRequests,
                          callback: function($$v) {
                            _vm.showPaintRequests = $$v
                          },
                          expression: "showPaintRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewInsulationJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "insulationSwitch",
                          label: _vm.$t("scaffold-requests.types.insulation")
                        },
                        model: {
                          value: _vm.showInsulationRequests,
                          callback: function($$v) {
                            _vm.showInsulationRequests = $$v
                          },
                          expression: "showInsulationRequests"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticClass: "pb-sm-0" },
            [
              false
                ? _c(
                    "v-row",
                    [
                      false
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                            },
                            [
                              _c("v-select", {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  label: _vm.$t(
                                    "scheduler.filter-by-request-type-label"
                                  ),
                                  disabled: _vm.processing,
                                  items: _vm.allRequestTypes,
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.selectedRequestTypes,
                                  callback: function($$v) {
                                    _vm.selectedRequestTypes = $$v
                                  },
                                  expression: "selectedRequestTypes"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scheduler.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: "",
                              dense: _vm.$vuetify.breakpoint.xsOnly
                            },
                            model: {
                              value: _vm.selectedContractors,
                              callback: function($$v) {
                                _vm.selectedContractors = $$v
                              },
                              expression: "selectedContractors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scheduler.filter-by-discipline-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableDisciplines,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: "",
                              dense: _vm.$vuetify.breakpoint.xsOnly
                            },
                            model: {
                              value: _vm.selectedDisciplines,
                              callback: function($$v) {
                                _vm.selectedDisciplines = $$v
                              },
                              expression: "selectedDisciplines"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewContractorFilter &&
                  !_vm.canViewDisciplinesFilter &&
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("v-col", {
                        staticClass: "pt-0 pb-0",
                        attrs: { cols: "12", sm: "6", md: "6" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas ||
                            _vm.selectableSubAreas.length == 0 ||
                            _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t(
                            "scheduler.filter-by-general-foreman-label"
                          ),
                          disabled: _vm.processing,
                          items: _vm.selectableGeneralForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedGeneralForemanIDs,
                          callback: function($$v) {
                            _vm.selectedGeneralForemanIDs = $$v
                          },
                          expression: "selectedGeneralForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-foreman-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedForemanIDs,
                          callback: function($$v) {
                            _vm.selectedForemanIDs = $$v
                          },
                          expression: "selectedForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-1 order-2",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-3",
                                                staticStyle: {
                                                  "min-width": "110px"
                                                },
                                                attrs: {
                                                  small: "",
                                                  rounded: "",
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  color: "#7a7a7a"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-printer\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("common.print")
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-left":
                                                      "2px !important"
                                                  },
                                                  attrs: { right: "" }
                                                },
                                                [_vm._v("mdi-menu-down")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { attrs: { subheader: "" } },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass: "fd-drop-down-button-badges"
                                    },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            inline: "",
                                            color: "primary",
                                            content: `${_vm.newVisibleToDoWorkOrders.length}`
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.printing.to-do-work-orders"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.newDownloadAndPrintPlannerReport(
                                                true,
                                                "pdf"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("scheduler.printing.pdf")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.newDownloadAndPrintPlannerReport(
                                                true,
                                                "xls"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.printing.excel"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", { staticClass: "my-3" }),
                                  _vm._v(" "),
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass: "fd-drop-down-button-badges"
                                    },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            inline: "",
                                            color: "primary",
                                            content: `${_vm.newVisibleWorkOrders.length}`
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.printing.all-work-orders"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.newDownloadAndPrintPlannerReport(
                                                false,
                                                "pdf"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("scheduler.printing.pdf")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.newDownloadAndPrintPlannerReport(
                                                false,
                                                "xls"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.printing.excel"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                disabled: _vm.processing,
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-2 order-1",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("fd-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-status-label"),
                          disabled: _vm.processing,
                          items: _vm.filterStatusesWithCounts,
                          "item-text": "name",
                          "item-value": "name",
                          "display-count": "",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedStatusNames,
                          callback: function($$v) {
                            _vm.selectedStatusNames = $$v
                          },
                          expression: "selectedStatusNames"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            key: _vm.anIncreasingNumber,
            ref: "datatable",
            class: [
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-actions-table",
              "fd-expanding-table",
              "fd-scheduler-list"
            ],
            attrs: {
              "data-cy": "list",
              "custom-sort": _vm.stickySortFilter,
              "custom-filter": _vm.searchFilter,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              headers: _vm.headers,
              items: _vm.newFilteredWorkOrders,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["internalNumber"],
              "sort-desc": true,
              "mobile-breakpoint": "0",
              "show-expand": "",
              "item-class": item =>
                item.isUrgent ? "fd-urgent-table-row-background" : ""
            },
            on: {
              "click:row": _vm.handleRowClick,
              "dblclick:row": _vm.handleRowDblClick,
              "item-expanded": _vm.itemExpanded
            },
            scopedSlots: _vm._u(
              [
                _vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "item.data-table-expand",
                      fn: function({ isExpanded, expand, item }) {
                        return [
                          _c("sp-inline-scope-icons", {
                            attrs: { item: item }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "v-data-table__expand-icon",
                                  class: {
                                    "v-data-table__expand-icon--active": isExpanded
                                  },
                                  on: {
                                    click: function($event) {
                                      return expand(!isExpanded)
                                    }
                                  }
                                },
                                [_vm._v("mdi-chevron-down")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  : null,
                {
                  key: "item.scaffoldNumber",
                  fn: function({ item }) {
                    return [
                      _c("fd-scaffold-number-with-badges", {
                        attrs: {
                          value: item.scaffoldNumber,
                          requestCount: item.activeRequests,
                          otherWorkOrderCount: item.activeWorkOrders,
                          workOrderCount: item.activeWorkOrders,
                          dismantleCount: item.hasActiveDismantle ? 1 : 0
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.requestType",
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.$format.uppercase(
                            _vm.$lookup.requestType(item.requestType)
                          )
                        )
                      )
                    ]
                  }
                },
                {
                  key: "item.todo",
                  fn: function({ item }) {
                    return [
                      _c("fd-checkbox", {
                        class: [
                          item.currentUserPermissions.canPushToToDoList
                            ? "pl-2"
                            : ""
                        ],
                        attrs: {
                          "data-cy": "todo",
                          value: _vm.getTodo(item),
                          disabled: _vm.processing,
                          readonly: !item.currentUserPermissions
                            .canPushToToDoList,
                          loading: _vm.updatingWorkOrderID == item.id
                        },
                        on: {
                          input: function($event) {
                            return _vm.flipTodo(item)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "footer.prepend",
                  fn: function() {
                    return [
                      _c("fd-archived-data-loader", {
                        staticClass: "ml-2 mr-3",
                        attrs: {
                          showArchived: _vm.showArchived,
                          showArchivedDateRange: _vm.showArchivedDateRange,
                          showArchivedMinDate: _vm.showArchivedMinDate,
                          showArchivedMaxDate: _vm.showArchivedMaxDate,
                          loading: _vm.archivedLoading,
                          disabled: _vm.processing
                        },
                        on: {
                          "input:showArchived": v => (_vm.showArchived = v),
                          "input:showArchivedDateRange": v =>
                            (_vm.showArchivedDateRange = v)
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.fd-nav",
                  fn: function() {
                    return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                  },
                  proxy: true
                },
                {
                  key: "item.archived",
                  fn: function({ item }) {
                    return [
                      _vm.showArchived
                        ? _c(
                            "v-row",
                            { attrs: { dense: "", "no-gutters": "" } },
                            [
                              _c("v-simple-checkbox", {
                                attrs: {
                                  value: !!item.archivedDate,
                                  disabled:
                                    _vm.processing ||
                                    _vm.itemMustStayArchived(item)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.flipArchived(item)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on }) {
                                          return [
                                            _vm.itemMustStayArchived(item)
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      staticClass: "ml-1",
                                                      attrs: {
                                                        small: "",
                                                        color: "black",
                                                        dark: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info")]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "scheduler.completed-work-orders-must-stay-archived"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.discipline",
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        "\n        " + _vm._s(item.disciplineName) + "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.priority",
                  fn: function({ item }) {
                    return [
                      item.isUrgent
                        ? _c("v-icon", { staticClass: "fd-urgent-icon" }, [
                            _vm._v("fa-fire-alt")
                          ])
                        : item.currentUserPermissions.canEditPriority
                        ? _c("v-select", {
                            staticClass: "fd-select-priority",
                            attrs: {
                              value: item.priority,
                              items: _vm.priorityValues
                            },
                            on: {
                              input: value =>
                                _vm.saveField(item, "priority", value)
                            }
                          })
                        : _c("div", [_vm._v(_vm._s(item.priority))])
                    ]
                  }
                },
                {
                  key: "item.progress",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditProgress &&
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-select", {
                            staticClass: "fd-select-progress",
                            attrs: {
                              value: item.progress,
                              items: _vm.progressValues
                            },
                            on: {
                              input: value =>
                                _vm.updateWorkOrderProgress(item, value)
                            }
                          })
                        : _c("div", [_vm._v(_vm._s(item.progress))])
                    ]
                  }
                },
                {
                  key: "item.assignedContractor",
                  fn: function({ item }) {
                    return [
                      item.currentUserPermissions.canEditAssignedContractor
                        ? _c("v-select", {
                            staticClass: "fd-select-foreman",
                            attrs: {
                              value: item.assignedContractorID,
                              items: _vm.assignableContractorsForType(
                                item.requestType
                              ),
                              "item-text": "name",
                              "item-value": "id",
                              clearable: ""
                            },
                            on: {
                              input: value =>
                                _vm.saveField(
                                  item,
                                  "assignedContractorID",
                                  value
                                )
                            }
                          })
                        : _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$lookup.contractor(
                                  item.assignedContractorID
                                )
                              )
                            )
                          ])
                    ]
                  }
                },
                {
                  key: "item.coordinatorName",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditAssignedCoordinator
                        ? _c(
                            "div",
                            [
                              _c("v-select", {
                                staticClass: "fd-select-foreman",
                                attrs: {
                                  value: item.coordinatorID,
                                  items: _vm.getAssignableCoordinatorsForWorkOrder(
                                    item
                                  ),
                                  "item-text": "name",
                                  "item-value": "id",
                                  clearable: ""
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(item, "coordinatorID", value)
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(
                              _vm._s(_vm.$lookup.person(item.coordinatorID))
                            )
                          ])
                    ]
                  }
                },
                {
                  key: "item.generalForemanName",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditAssignedGeneralForeman
                        ? _c("v-select", {
                            staticClass: "fd-select-foreman",
                            attrs: {
                              "data-id": item.id,
                              "data-cy": "generalforeman",
                              value: item.generalForemanID,
                              items: _vm.getGeneralForemenForWorkOrder(item),
                              "item-text": "name",
                              "item-value": "id",
                              clearable: ""
                            },
                            on: {
                              input: value =>
                                _vm.saveField(item, "generalForemanID", value)
                            }
                          })
                        : _c("div", [
                            _vm._v(
                              _vm._s(_vm.$lookup.person(item.generalForemanID))
                            )
                          ])
                    ]
                  }
                },
                {
                  key: "item.foremanName",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditAssignedForeman &&
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c("v-select", {
                            staticClass: "fd-select-foreman",
                            attrs: {
                              "data-id": item.id,
                              "data-cy": "foreman",
                              value: item.foremanID,
                              items: _vm.getForemenForWorkOrder(item),
                              "item-text": "name",
                              "item-value": "id",
                              clearable: ""
                            },
                            on: {
                              input: value =>
                                _vm.saveField(item, "foremanID", value)
                            }
                          })
                        : _c("div", [
                            _vm._v(_vm._s(_vm.$lookup.person(item.foremanID)))
                          ])
                    ]
                  }
                },
                {
                  key: "item.workOrderStatus",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditStatus &&
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mx-0 fd-status-column",
                              staticStyle: { position: "relative" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      value: item.workOrderStatus,
                                      items: _vm.selectableStatusesForWorkOrder(
                                        item
                                      ),
                                      "item-text": "displayName",
                                      "item-value": "value"
                                    },
                                    on: {
                                      input: value =>
                                        _vm.updateWorkOrderStatus(item, value)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.workOrderIsOnHold(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement"
                                        },
                                        [
                                          _vm._v(
                                            "\n              fas fa-exclamation-circle\n            "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsCancelled(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement"
                                        },
                                        [
                                          _vm._v(
                                            "\n              fas fa-times-octagon\n            "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsInScheduling(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement"
                                        },
                                        [
                                          _vm._v(
                                            "\n              fas fa-circle\n            "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsStarted(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement"
                                        },
                                        [
                                          _vm._v(
                                            "\n              fas fa-circle\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "v-row",
                            {
                              staticClass: "mx-0 fd-status-column",
                              staticStyle: { position: "relative" }
                            },
                            [
                              _vm.workOrderIsOnHold(item)
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                    },
                                    [
                                      _vm._v(
                                        "\n            fas fa-exclamation-circle\n          "
                                      )
                                    ]
                                  )
                                : _vm.workOrderIsCancelled(item)
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                    },
                                    [
                                      _vm._v(
                                        "\n            fas fa-times-octagon\n          "
                                      )
                                    ]
                                  )
                                : _vm.workOrderIsInScheduling(item)
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                    },
                                    [
                                      _vm._v(
                                        "\n            fas fa-circle\n          "
                                      )
                                    ]
                                  )
                                : _vm.workOrderIsStarted(item)
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                    },
                                    [
                                      _vm._v(
                                        "\n            fas fa-circle\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$format.uppercase(
                                      _vm.$lookup.workOrderStatus(
                                        item.workOrderStatus
                                      )
                                    )
                                  ) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                    ]
                  }
                },
                {
                  key: "item.requiredDate",
                  fn: function({ item }) {
                    return [
                      _vm.currentUserCanEditWorkOrderSchedule &&
                      item.currentUserPermissions.canEditRequiredDate
                        ? _c("fd-date-picker", {
                            attrs: {
                              outlined: false,
                              value: item.requiredDate,
                              "css-class": "",
                              format: _vm.dateformat,
                              min: new Date(),
                              "display-badge": _vm.showRequiredDatePushedBadge(
                                item
                              ),
                              "badge-content": _vm.requiredDatePushedBadgeContent(
                                item
                              )
                            },
                            on: {
                              input: value =>
                                _vm.saveField(item, "requiredDate", value)
                            }
                          })
                        : _c("div", [
                            _vm._v(_vm._s(_vm.$format.date(item.requiredDate)))
                          ])
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-2-actions-column-min-width" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on }) {
                                      return [
                                        _c(
                                          "v-badge",
                                          {
                                            attrs: {
                                              color: "red",
                                              overlap: "",
                                              dot: "",
                                              value: item.hasDismantleConflict
                                            }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    link: "",
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openActiveWorkDetailsForScaffoldDialog(
                                                        item,
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "20px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-view-list\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "scheduler.view-scaffold-active-work-orders"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                to: `/scheduler/${item.id}`
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-pencil\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.edit")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function({ item }) {
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.expanderColSpan } },
                        [
                          _c("fd-work-order-details", {
                            staticClass:
                              "fd-expanding-table-single-details-section",
                            attrs: {
                              workOrder: item.workOrderDetails,
                              walkdown: item.walkdownDetails,
                              "show-edit-controls":
                                _vm.currentUserCanEditWorkOrderSchedule &&
                                item.currentUserPermissions.canEditPriority,
                              "edit-controls-disabled":
                                !(
                                  _vm.currentUserCanEditWorkOrderSchedule &&
                                  item.currentUserPermissions.canEditPriority
                                ) || _vm.processing
                            },
                            on: {
                              "change:isurgent": v =>
                                _vm.saveField(item, "isUrgent", v)
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          false
            ? _c("v-data-table", {
                directives: [
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:id.hide-when-header-text-empty",
                    value: "",
                    expression: "''",
                    arg: "id",
                    modifiers: { "hide-when-header-text-empty": true }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:requestNumber.hide-when-header-text-empty",
                    value: "",
                    expression: "''",
                    arg: "requestNumber",
                    modifiers: { "hide-when-header-text-empty": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:priority.hide-when-small",
                    value: _vm.$t("scheduler.priority"),
                    expression: "$t('scheduler.priority')",
                    arg: "priority",
                    modifiers: { "hide-when-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:progress.hide-when-large",
                    value: _vm.$t("scheduler.progress-short"),
                    expression: "$t('scheduler.progress-short')",
                    arg: "progress",
                    modifiers: { "hide-when-large": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:internalNumber.hide-when-medium",
                    value: _vm.$vuetify.breakpoint.lgAndUp
                      ? _vm.$t("scheduler.wo-number-column-header")
                      : _vm.$t("scheduler.wo-number-column-header-short"),
                    expression:
                      "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.wo-number-column-header')\n          : $t('scheduler.wo-number-column-header-short')\n      ",
                    arg: "internalNumber",
                    modifiers: { "hide-when-medium": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:scaffoldNumber",
                    value: _vm.$vuetify.breakpoint.lgAndUp
                      ? _vm.$t("scheduler.scaffold-number")
                      : _vm.$t("scheduler.scaffold-number-short"),
                    expression:
                      "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.scaffold-number')\n          : $t('scheduler.scaffold-number-short')\n      ",
                    arg: "scaffoldNumber"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:requestType.hide-when-medium",
                    value: _vm.$column(
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _vm.$t("scheduler.request-type-column-header")
                        : _vm.$t("scheduler.request-type-column-header-short")
                    ),
                    expression:
                      "\n        $column(\n          $vuetify.breakpoint.lgAndUp\n            ? $t('scheduler.request-type-column-header')\n            : $t('scheduler.request-type-column-header-short')\n        )\n      ",
                    arg: "requestType",
                    modifiers: { "hide-when-medium": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:workOrderStatus",
                    value: _vm.$t("scheduler.status"),
                    expression: "$t('scheduler.status')",
                    arg: "workOrderStatus"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:requiredDate.hide-when-extra-small",
                    value: _vm.$vuetify.breakpoint.lgAndUp
                      ? _vm.$t("scheduler.required-date-column-header")
                      : _vm.$t("scheduler.required-date-column-header-short"),
                    expression:
                      "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.required-date-column-header')\n          : $t('scheduler.required-date-column-header-short')\n      ",
                    arg: "requiredDate",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:coordinatorName.hide-when-small",
                    value: _vm.$t("scheduler.coordinator"),
                    expression: "$t('scheduler.coordinator')",
                    arg: "coordinatorName",
                    modifiers: { "hide-when-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:generalForemanName.hide-when-extra-small",
                    value: _vm.$vuetify.breakpoint.lgAndUp
                      ? _vm.$t("scheduler.general-foreman-short")
                      : _vm.$t("scheduler.general-foreman-short"),
                    expression:
                      "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.general-foreman-short')\n          : $t('scheduler.general-foreman-short')\n      ",
                    arg: "generalForemanName",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:foremanName.hide-when-extra-small",
                    value: _vm.$t("scheduler.foreman-short"),
                    expression: "$t('scheduler.foreman-short')",
                    arg: "foremanName",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:assignedContractor.hide-when-large",
                    value: _vm.$t("scheduler.assigned-contractor"),
                    expression: "$t('scheduler.assigned-contractor')",
                    arg: "assignedContractor",
                    modifiers: { "hide-when-large": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:todo",
                    value: _vm.$t("scheduler.to-do"),
                    expression: "$t('scheduler.to-do')",
                    arg: "todo"
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:archived.hide-when-mobile.hide-when-header-text-empty",
                    value: _vm.showArchived ? _vm.$t("common.archived") : "",
                    expression:
                      "\n        showArchived ? $t('common.archived') : ''\n      ",
                    arg: "archived",
                    modifiers: {
                      "hide-when-mobile": true,
                      "hide-when-header-text-empty": true
                    }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                    arg: "actions",
                    modifiers: {
                      "no-sort": true,
                      "hide-when-extra-small": true,
                      "class_fd-actions-cell": true
                    }
                  },
                  {
                    name: "fd-row-navigate",
                    rawName: "v-fd-row-navigate.show-when-extra-small",
                    value: item => _vm.$router.push(`/scheduler/${item.id}`),
                    expression: "item => $router.push(`/scheduler/${item.id}`)",
                    modifiers: { "show-when-extra-small": true }
                  }
                ],
                ref: "datatable",
                class: [
                  { "fd-archive-bonus-padding-bottom": _vm.showArchived },
                  "fd-actions-table",
                  "fd-expanding-table",
                  "fd-scheduler-list"
                ],
                attrs: {
                  "data-cy": "list",
                  "custom-sort": _vm.stickySortFilter,
                  "custom-filter": _vm.searchFilter,
                  "items-per-page": _vm.itemsPerPage,
                  "footer-props": {
                    "items-per-page-options": _vm.itemsPerPageOptions
                  },
                  items: _vm.newFilteredWorkOrders,
                  search: _vm.tablesearch,
                  loading: _vm.processing,
                  "loading-text": _vm.$t("common.table-loading-message"),
                  "sort-by": ["internalNumber"],
                  "sort-desc": true,
                  "mobile-breakpoint": "0",
                  "show-expand": "",
                  "item-class": item =>
                    item.isUrgent ? "fd-urgent-table-row-background" : ""
                },
                on: { "item-expanded": _vm.itemExpanded },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? {
                          key: "item.data-table-expand",
                          fn: function({ isExpanded, expand, item }) {
                            return [
                              _c("sp-inline-scope-icons", {
                                attrs: { item: item }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  attrs: { justify: "center", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "v-data-table__expand-icon",
                                      class: {
                                        "v-data-table__expand-icon--active": isExpanded
                                      },
                                      on: {
                                        click: function($event) {
                                          return expand(!isExpanded)
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-chevron-down")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      : null,
                    {
                      key: "item.scaffoldNumber",
                      fn: function({ item }) {
                        return [
                          _c("fd-scaffold-number-with-badges", {
                            attrs: {
                              value: item.scaffoldNumber,
                              requestCount: item.activeRequests,
                              otherWorkOrderCount: item.activeWorkOrders,
                              workOrderCount: item.activeWorkOrders,
                              dismantleCount: item.hasActiveDismantle ? 1 : 0
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.requestType",
                      fn: function({ item }) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.$format.uppercase(
                                _vm.$lookup.requestType(item.requestType)
                              )
                            )
                          )
                        ]
                      }
                    },
                    {
                      key: "item.todo",
                      fn: function({ item }) {
                        return [
                          _c("fd-checkbox", {
                            class: [
                              item.currentUserPermissions.canPushToToDoList
                                ? "pl-2"
                                : ""
                            ],
                            attrs: {
                              "data-cy": "todo",
                              value: _vm.getTodo(item),
                              disabled: _vm.processing,
                              readonly: !item.currentUserPermissions
                                .canPushToToDoList,
                              loading: _vm.updatingWorkOrderID == item.id
                            },
                            on: {
                              input: function($event) {
                                return _vm.flipTodo(item)
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "footer.prepend",
                      fn: function() {
                        return [
                          _c("fd-archived-data-loader", {
                            staticClass: "ml-2 mr-3",
                            attrs: {
                              showArchived: _vm.showArchived,
                              showArchivedDateRange: _vm.showArchivedDateRange,
                              showArchivedMinDate: _vm.showArchivedMinDate,
                              showArchivedMaxDate: _vm.showArchivedMaxDate,
                              loading: _vm.archivedLoading,
                              disabled: _vm.processing
                            },
                            on: {
                              "input:showArchived": v => (_vm.showArchived = v),
                              "input:showArchivedDateRange": v =>
                                (_vm.showArchivedDateRange = v)
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.fd-nav",
                      fn: function() {
                        return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                      },
                      proxy: true
                    },
                    {
                      key: "item.archived",
                      fn: function({ item }) {
                        return [
                          _vm.showArchived
                            ? _c(
                                "v-row",
                                { attrs: { dense: "", "no-gutters": "" } },
                                [
                                  _c("v-simple-checkbox", {
                                    attrs: {
                                      value: !!item.archivedDate,
                                      disabled:
                                        _vm.processing ||
                                        _vm.itemMustStayArchived(item)
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.flipArchived(item)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                _vm.itemMustStayArchived(item)
                                                  ? _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            small: "",
                                                            color: "black",
                                                            dark: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.completed-work-orders-must-stay-archived"
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.discipline",
                      fn: function({ item }) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(item.disciplineName) +
                              "\n      "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.priority",
                      fn: function({ item }) {
                        return [
                          item.isUrgent
                            ? _c("v-icon", { staticClass: "fd-urgent-icon" }, [
                                _vm._v("fa-fire-alt")
                              ])
                            : item.currentUserPermissions.canEditPriority
                            ? _c("v-select", {
                                staticClass: "fd-select-priority",
                                attrs: {
                                  value: item.priority,
                                  items: _vm.priorityValues
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(item, "priority", value)
                                }
                              })
                            : _c("div", [_vm._v(_vm._s(item.priority))])
                        ]
                      }
                    },
                    {
                      key: "item.progress",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions.canEditProgress &&
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c("v-select", {
                                staticClass: "fd-select-progress",
                                attrs: {
                                  value: item.progress,
                                  items: _vm.progressValues
                                },
                                on: {
                                  input: value =>
                                    _vm.updateWorkOrderProgress(item, value)
                                }
                              })
                            : _c("div", [_vm._v(_vm._s(item.progress))])
                        ]
                      }
                    },
                    {
                      key: "item.assignedContractor",
                      fn: function({ item }) {
                        return [
                          item.currentUserPermissions.canEditAssignedContractor
                            ? _c("v-select", {
                                staticClass: "fd-select-foreman",
                                attrs: {
                                  value: item.assignedContractorID,
                                  items: _vm.assignableContractorsForType(
                                    item.requestType
                                  ),
                                  "item-text": "name",
                                  "item-value": "id",
                                  clearable: ""
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(
                                      item,
                                      "assignedContractorID",
                                      value
                                    )
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$lookup.contractor(
                                      item.assignedContractorID
                                    )
                                  )
                                )
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.coordinatorName",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions.canEditAssignedCoordinator
                            ? _c(
                                "div",
                                [
                                  _c("v-select", {
                                    staticClass: "fd-select-foreman",
                                    attrs: {
                                      value: item.coordinatorID,
                                      items: _vm.getAssignableCoordinatorsForWorkOrder(
                                        item
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      clearable: ""
                                    },
                                    on: {
                                      input: value =>
                                        _vm.saveField(
                                          item,
                                          "coordinatorID",
                                          value
                                        )
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$lookup.person(item.coordinatorID))
                                )
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.generalForemanName",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions
                            .canEditAssignedGeneralForeman
                            ? _c("v-select", {
                                staticClass: "fd-select-foreman",
                                attrs: {
                                  "data-id": item.id,
                                  "data-cy": "generalforeman",
                                  value: item.generalForemanID,
                                  items: _vm.getGeneralForemenForWorkOrder(
                                    item
                                  ),
                                  "item-text": "name",
                                  "item-value": "id",
                                  clearable: ""
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(
                                      item,
                                      "generalForemanID",
                                      value
                                    )
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$lookup.person(item.generalForemanID)
                                  )
                                )
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.foremanName",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions.canEditAssignedForeman &&
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c("v-select", {
                                staticClass: "fd-select-foreman",
                                attrs: {
                                  "data-id": item.id,
                                  "data-cy": "foreman",
                                  value: item.foremanID,
                                  items: _vm.getForemenForWorkOrder(item),
                                  "item-text": "name",
                                  "item-value": "id",
                                  clearable: ""
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(item, "foremanID", value)
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$lookup.person(item.foremanID))
                                )
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.workOrderStatus",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions.canEditStatus &&
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 fd-status-column",
                                  staticStyle: { position: "relative" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          value: item.workOrderStatus,
                                          items: _vm.selectableStatusesForWorkOrder(
                                            item
                                          ),
                                          "item-text": "displayName",
                                          "item-value": "value"
                                        },
                                        on: {
                                          input: value =>
                                            _vm.updateWorkOrderStatus(
                                              item,
                                              value
                                            )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.workOrderIsOnHold(item)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement"
                                            },
                                            [
                                              _vm._v(
                                                "\n              fas fa-exclamation-circle\n            "
                                              )
                                            ]
                                          )
                                        : _vm.workOrderIsCancelled(item)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement"
                                            },
                                            [
                                              _vm._v(
                                                "\n              fas fa-times-octagon\n            "
                                              )
                                            ]
                                          )
                                        : _vm.workOrderIsInScheduling(item)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement"
                                            },
                                            [
                                              _vm._v(
                                                "\n              fas fa-circle\n            "
                                              )
                                            ]
                                          )
                                        : _vm.workOrderIsStarted(item)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement"
                                            },
                                            [
                                              _vm._v(
                                                "\n              fas fa-circle\n            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 fd-status-column",
                                  staticStyle: { position: "relative" }
                                },
                                [
                                  _vm.workOrderIsOnHold(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                        },
                                        [
                                          _vm._v(
                                            "\n            fas fa-exclamation-circle\n          "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsCancelled(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                        },
                                        [
                                          _vm._v(
                                            "\n            fas fa-times-octagon\n          "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsInScheduling(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                        },
                                        [
                                          _vm._v(
                                            "\n            fas fa-circle\n          "
                                          )
                                        ]
                                      )
                                    : _vm.workOrderIsStarted(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                        },
                                        [
                                          _vm._v(
                                            "\n            fas fa-circle\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$format.uppercase(
                                          _vm.$lookup.workOrderStatus(
                                            item.workOrderStatus
                                          )
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "item.requiredDate",
                      fn: function({ item }) {
                        return [
                          _vm.currentUserCanEditWorkOrderSchedule &&
                          item.currentUserPermissions.canEditRequiredDate
                            ? _c("fd-date-picker", {
                                attrs: {
                                  outlined: false,
                                  value: item.requiredDate,
                                  "css-class": "",
                                  format: _vm.dateformat,
                                  min: new Date(),
                                  "display-badge": _vm.showRequiredDatePushedBadge(
                                    item
                                  ),
                                  "badge-content": _vm.requiredDatePushedBadgeContent(
                                    item
                                  )
                                },
                                on: {
                                  input: value =>
                                    _vm.saveField(item, "requiredDate", value)
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$format.date(item.requiredDate))
                                )
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-2-actions-column-min-width" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on }) {
                                          return [
                                            _c(
                                              "v-badge",
                                              {
                                                attrs: {
                                                  color: "red",
                                                  overlap: "",
                                                  dot: "",
                                                  value:
                                                    item.hasDismantleConflict
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        link: "",
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openActiveWorkDetailsForScaffoldDialog(
                                                            item,
                                                            false
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "20px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-view-list\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "scheduler.view-scaffold-active-work-orders"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    link: "",
                                                    disabled: _vm.processing,
                                                    to: `/scheduler/${item.id}`
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                  mdi-pencil\n                "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.edit")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "expanded-item",
                      fn: function({ item }) {
                        return [
                          _c(
                            "td",
                            { attrs: { colspan: _vm.expanderColSpan } },
                            [
                              _c("fd-work-order-details", {
                                staticClass:
                                  "fd-expanding-table-single-details-section",
                                attrs: {
                                  workOrder: item.workOrderDetails,
                                  walkdown: item.walkdownDetails,
                                  "show-edit-controls":
                                    _vm.currentUserCanEditWorkOrderSchedule &&
                                    item.currentUserPermissions.canEditPriority,
                                  "edit-controls-disabled":
                                    !(
                                      _vm.currentUserCanEditWorkOrderSchedule &&
                                      item.currentUserPermissions
                                        .canEditPriority
                                    ) || _vm.processing
                                },
                                on: {
                                  "change:isurgent": v =>
                                    _vm.saveField(item, "isUrgent", v)
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }