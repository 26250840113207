export default {
  maintenance: "fas fa-toolbox",
  paint: "fas fa-brush",
  insulation: "fas fa-circle-dot",
  scaffolds: "fas fa-layer-group",
  inspections: "fas fa-clipboard-check",
  imports: "fas fa-file-import",
  "client-work-order": "fa-star-of-life",
  "service-order": "fa-diamond",
  "change-order": "fa-hammer-crash",
  rework: "fa-repeat",
  "scaffold-designs": "fas fa-compass-drafting",
  "scaffolds-map": "fa-map-location",
  "scaffold-location": "fa-location-dot",
  "inspection-time-sunrise": "mdi-weather-sunset-up",
  "inspection-time-day": "mdi-white-balance-sunny",
  "inspection-time-sunset": "mdi-weather-sunset-down",
  "inspection-time-night": "mdi-moon-waning-crescent",
  "contractor-norms": "fa-file-pen",
  "contractor-purchase-orders": "fa-file-invoice-dollar",
  classification: "fa-screen-users"
} as { [key: string]: string };

