var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: {
          "complete-button-label-key": "common.done",
          name: _vm.imageName
        },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      !_vm.currentUserCanSubmitScaffoldRequests
        ? _c("v-alert", { staticClass: "mt-0 mb-0", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("scaffold-requests.no-permission-to-submit")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "scaffoldrequestnew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("scaffold-requests.new-dialog-title")) +
                      "\n        "
                  ),
                  !!_vm.selectedRequestingContractorName &&
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            ` | ${_vm.selectedRequestingContractorName}`
                          )
                        }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                {
                  staticClass: "fd-responsive-stepper",
                  attrs: { height: "60" }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.basicStep,
                        step: _vm.basicStep,
                        editable: "",
                        rules: [() => !_vm.basicStepError]
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("scaffold-requests.new.steps.details")
                          ) +
                          "\n          "
                      ),
                      _vm.basicStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-requests.new.steps.details-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      ref: "scopestep",
                      attrs: {
                        "data-cy": "scopestep",
                        complete: _vm.step > _vm.scopeStep,
                        step: _vm.scopeStep,
                        editable: "",
                        rules: [() => !_vm.scopeStepError]
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("scaffold-requests.new.steps.scope-change")
                          ) +
                          "\n          "
                      ),
                      _vm.scopeStepError
                        ? _c("small", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "scaffold-requests.new.steps.scope-change-error-message"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.photosStep,
                        step: _vm.photosStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("scaffold-requests.new.steps.photos")) +
                          "\n          "
                      ),
                      _vm.photosStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-requests.new.steps.photos-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.notesStep,
                        step: _vm.notesStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("scaffold-requests.new.steps.notes")) +
                          "\n          "
                      ),
                      _vm.notesStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-requests.new.steps.notes-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.optionsStep,
                        step: _vm.optionsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("scaffold-requests.new.steps.options")
                          ) +
                          "\n          "
                      ),
                      _vm.optionsStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-requests.new.steps.options-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.attachmentsStep,
                        step: _vm.attachmentsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("scaffold-requests.new.steps.attachments")
                          ) +
                          "\n          "
                      ),
                      _vm.attachmentsStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-requests.new.steps.attachments-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.basicStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "basicform",
                          staticClass: "request-form",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items":
                                            _vm.availableKeywords,
                                          "selected-items":
                                            _vm.selectedKeywords,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pt-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "request-type",
                                          label: _vm.$t(
                                            "scaffold-requests.request-type"
                                          ),
                                          disabled: _vm.processing,
                                          items: _vm.requestTypes,
                                          rules:
                                            _vm.scaffoldRequestRules.requestType
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.requestType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requestType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requestType"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "request-sub-type",
                                          label: _vm.$t(
                                            "scaffold-requests.request-sub-type"
                                          ),
                                          disabled: _vm.processing,
                                          items: _vm.requestSubTypes,
                                          rules:
                                            _vm.scaffoldRequestRules
                                              .requestSubType
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.requestSubType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requestSubType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requestSubType"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-date-picker", {
                                        attrs: {
                                          cy: "requireddate",
                                          disabled: _vm.processing,
                                          label: _vm.$t(
                                            "scaffold-requests.required-date"
                                          ),
                                          rules:
                                            _vm.scaffoldRequestRules
                                              .requiredDate,
                                          clearable: "",
                                          min: _vm.currentUserCanEnterHistoricalData
                                            ? undefined
                                            : "today"
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.requiredDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requiredDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requiredDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      !_vm.scaffoldRequest.requestType ||
                                      _vm.isErectRequest
                                        ? _c("fd-date-picker", {
                                            attrs: {
                                              value:
                                                _vm.scaffoldRequest
                                                  .requiredUntilDate,
                                              disabled:
                                                (!!_vm.scaffoldRequest
                                                  .requestType &&
                                                  !_vm.isErectRequest) ||
                                                _vm.processing,
                                              label: _vm.$t(
                                                "scaffold-requests.required-until-date"
                                              ),
                                              rules:
                                                _vm.scaffoldRequestRules
                                                  .requiredUntilDate,
                                              clearable: "",
                                              min: !!_vm.scaffoldRequest
                                                .requiredDate
                                                ? _vm.scaffoldRequest
                                                    .requiredDate
                                                : "today"
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  _vm.scaffoldRequest,
                                                  "requiredUntilDate",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.requestIsForScaffold &&
                                      !!_vm.scaffoldRequest.requestType &&
                                      !_vm.isErectRequest
                                        ? _c("fd-async-search-box", {
                                            attrs: {
                                              refreshItems: _vm.loadScaffolds,
                                              items: _vm.availableScaffolds,
                                              selectedItems:
                                                _vm.selectedScaffold,
                                              label: _vm.$t(
                                                "scaffold-requests.tag-number"
                                              ),
                                              multiple: false,
                                              itemText: "search",
                                              itemDetail: "details",
                                              chipClass:
                                                "keywords-selection-chip-colors-secondary",
                                              disabled:
                                                !_vm.scaffoldRequest
                                                  .requestType ||
                                                _vm.isErectRequest ||
                                                _vm.processing,
                                              placeholder: _vm.$t(
                                                "scaffold-requests.tag-select-placeholder"
                                              ),
                                              rules:
                                                _vm.scaffoldRequestRules
                                                  .scaffoldNumber
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedScaffold = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedScaffold = $event
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function({ item }) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.description
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item",
                                                  fn: function({ item }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item.description
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              !!item.externalReferenceNumber
                                                                ? _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 white--text",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "#A9A9A9"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.externalReferenceNumber
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          !!item.details
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fd-item-detail-light pb-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.details
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              215792771
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !!_vm.requestingContractors.length &&
                                  _vm.requestingContractors.length > 1
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-0",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scaffold-requests.requesting-contractor"
                                              ),
                                              disabled: _vm.processing,
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.requestingContractors,
                                              rules:
                                                _vm.scaffoldRequestRules
                                                  .requestingContractorID,
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.scaffoldRequest
                                                  .requestingContractorID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffoldRequest,
                                                  "requestingContractorID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffoldRequest.requestingContractorID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.requestIsForScaffold
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          !!_vm.scaffoldRequest.requestType &&
                                          _vm.isErectRequest &&
                                          _vm.$store.state.curEnvironment
                                            .enableScaffoldDesign
                                            ? _c("fd-async-search-box", {
                                                attrs: {
                                                  refreshItems:
                                                    _vm.loadScaffoldDesigns,
                                                  items: _vm.availableDesigns,
                                                  selectedItems:
                                                    _vm.selectedDesign,
                                                  label: _vm.$t(
                                                    "scaffold-requests.designed-tag-number"
                                                  ),
                                                  multiple: false,
                                                  itemText:
                                                    "formattedScaffoldNumber",
                                                  chipClass:
                                                    "keywords-selection-chip-colors-secondary",
                                                  disabled:
                                                    !_vm.scaffoldRequest
                                                      .requestType ||
                                                    !_vm.isErectRequest ||
                                                    _vm.processing
                                                },
                                                on: {
                                                  "update:selectedItems": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedDesign = $event
                                                  },
                                                  "update:selected-items": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedDesign = $event
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !!_vm.requestIsForScaffold &&
                                  (!_vm.requestingContractors.length ||
                                    _vm.requestingContractors.length == 1)
                                    ? _c("v-col", {
                                        staticClass:
                                          "justify-space-between pt-1 pb-0",
                                        attrs: {
                                          align: "center",
                                          cols: "0",
                                          sm: "6"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.discipline"
                                          ),
                                          disabled:
                                            !_vm.scaffoldRequest
                                              .requestingContractorID ||
                                            _vm.processing,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.disciplines,
                                          rules:
                                            _vm.scaffoldRequestRules
                                              .disciplineID,
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.disciplineID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "disciplineID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.disciplineID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.requestor"
                                          ),
                                          disabled:
                                            !_vm.scaffoldRequest.disciplineID ||
                                            _vm.processing,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.requestors,
                                          rules:
                                            _vm.scaffoldRequestRules
                                              .requestingEmployeeID,
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .requestingEmployeeID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requestingEmployeeID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requestingEmployeeID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        class: {
                                          "fd-readonly":
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign
                                        },
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.area"
                                          ),
                                          disabled:
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign ||
                                            !_vm.scaffoldRequest
                                              .requestingContractorID ||
                                            _vm.processing,
                                          readonly:
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign,
                                          dense:
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.areas,
                                          rules:
                                            _vm.scaffoldRequestRules.areaID,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldRequest.areaID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "areaID",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldRequest.areaID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        class: {
                                          "fd-readonly":
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign
                                        },
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.sub-area"
                                          ),
                                          disabled:
                                            !_vm.scaffoldRequest.areaID ||
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign ||
                                            _vm.processing,
                                          readonly:
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign,
                                          dense:
                                            _vm.dataSetByScaffold ||
                                            _vm.dataSetByDesign,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.subAreas,
                                          rules:
                                            _vm.scaffoldRequestRules.subAreaID,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldRequest.subAreaID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "subAreaID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.subAreaID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.site-contact"
                                          ),
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldRequestRules.siteContact
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.siteContact,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "siteContact",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.siteContact"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          cy: "location",
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.location"
                                          ),
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldRequestRules.location,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .specificWorkLocation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "specificWorkLocation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.specificWorkLocation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.description"
                                          ),
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldRequestRules
                                              .description,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .detailedWorkDescription,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "detailedWorkDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.detailedWorkDescription"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0 reduce-underlying-control-padding",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        md: "12"
                                      }
                                    },
                                    [
                                      _c("fd-work-package-selector", {
                                        attrs: {
                                          loading: _vm.processing,
                                          refreshItems: _vm.loadWorkPackages,
                                          items: _vm.availableIWPs,
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.selectedIWPs,
                                          callback: function($$v) {
                                            _vm.selectedIWPs = $$v
                                          },
                                          expression: "selectedIWPs"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.scopeStep }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("sp-work-order-scope-details-form", {
                            ref: "scopechangeform",
                            attrs: {
                              cy: "scopechangeform",
                              disabled: _vm.processing,
                              isClientWorkOrder:
                                _vm.scaffoldRequest.isClientWorkOrder,
                              clientWorkOrderReferenceNumber:
                                _vm.scaffoldRequest
                                  .clientWorkOrderReferenceNumber,
                              clientWorkOrderReferenceNumberRules:
                                _vm.scaffoldRequestRules
                                  .clientWorkOrderReferenceNumber,
                              clientWorkOrderReason:
                                _vm.scaffoldRequest.clientWorkOrderReason,
                              clientWorkOrderReasonRules:
                                _vm.scaffoldRequestRules.clientWorkOrderReason,
                              isChangeOrder: _vm.scaffoldRequest.isChangeOrder,
                              changeOrderReferenceNumber:
                                _vm.scaffoldRequest.changeOrderReferenceNumber,
                              changeOrderReferenceNumberRules:
                                _vm.scaffoldRequestRules
                                  .changeOrderReferenceNumber,
                              changeOrderReason:
                                _vm.scaffoldRequest.changeOrderReason,
                              changeOrderReasonRules:
                                _vm.scaffoldRequestRules.changeOrderReason,
                              isRework: _vm.scaffoldRequest.isRework,
                              reworkReferenceNumber:
                                _vm.scaffoldRequest.reworkReferenceNumber,
                              reworkReferenceNumberRules:
                                _vm.scaffoldRequestRules.reworkReferenceNumber,
                              reworkReason: _vm.scaffoldRequest.reworkReason,
                              reworkReasonRules:
                                _vm.scaffoldRequestRules.reworkReason,
                              isServiceOrder:
                                _vm.scaffoldRequest.isServiceOrder,
                              serviceOrderReferenceNumber:
                                _vm.scaffoldRequest.serviceOrderReferenceNumber,
                              serviceOrderReferenceNumberRules:
                                _vm.scaffoldRequestRules
                                  .serviceOrderReferenceNumber,
                              serviceOrderReason:
                                _vm.scaffoldRequest.serviceOrderReason,
                              serviceOrderReasonRules:
                                _vm.scaffoldRequestRules.serviceOrderReason,
                              "purchase-orders":
                                _vm.$store.state.curEnvironment
                                  .enablePurchaseOrders,
                              purchaseOrderID:
                                _vm.scaffoldRequest.purchaseOrderID,
                              purchaseOrderIDRules:
                                _vm.scaffoldRequestRules.purchaseOrderNumber,
                              purchaseOrderDisabled: !_vm.scaffoldRequest
                                .requestingContractorID,
                              selectablePurchaseOrders:
                                _vm.selectablePurchaseOrders,
                              existingTagNumber:
                                _vm.scaffoldRequest.existingTagNumber
                            },
                            on: {
                              "update:isClientWorkOrder": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isClientWorkOrder",
                                  $event
                                )
                              },
                              "update:is-client-work-order": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isClientWorkOrder",
                                  $event
                                )
                              },
                              "update:clientWorkOrderReferenceNumber": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "clientWorkOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:client-work-order-reference-number": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "clientWorkOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:clientWorkOrderReason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "clientWorkOrderReason",
                                  $event
                                )
                              },
                              "update:client-work-order-reason": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "clientWorkOrderReason",
                                  $event
                                )
                              },
                              "update:isChangeOrder": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isChangeOrder",
                                  $event
                                )
                              },
                              "update:is-change-order": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isChangeOrder",
                                  $event
                                )
                              },
                              "update:changeOrderReferenceNumber": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "changeOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:change-order-reference-number": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "changeOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:changeOrderReason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "changeOrderReason",
                                  $event
                                )
                              },
                              "update:change-order-reason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "changeOrderReason",
                                  $event
                                )
                              },
                              "update:isRework": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isRework",
                                  $event
                                )
                              },
                              "update:is-rework": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isRework",
                                  $event
                                )
                              },
                              "update:reworkReferenceNumber": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "reworkReferenceNumber",
                                  $event
                                )
                              },
                              "update:rework-reference-number": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "reworkReferenceNumber",
                                  $event
                                )
                              },
                              "update:reworkReason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "reworkReason",
                                  $event
                                )
                              },
                              "update:rework-reason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "reworkReason",
                                  $event
                                )
                              },
                              "update:isServiceOrder": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isServiceOrder",
                                  $event
                                )
                              },
                              "update:is-service-order": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "isServiceOrder",
                                  $event
                                )
                              },
                              "update:serviceOrderReferenceNumber": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "serviceOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:service-order-reference-number": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "serviceOrderReferenceNumber",
                                  $event
                                )
                              },
                              "update:serviceOrderReason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "serviceOrderReason",
                                  $event
                                )
                              },
                              "update:service-order-reason": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "serviceOrderReason",
                                  $event
                                )
                              },
                              "update:purchaseOrderID": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "purchaseOrderID",
                                  $event
                                )
                              },
                              "update:purchase-order-i-d": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "purchaseOrderID",
                                  $event
                                )
                              },
                              "update:existingTagNumber": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "existingTagNumber",
                                  $event
                                )
                              },
                              "update:existing-tag-number": function($event) {
                                return _vm.$set(
                                  _vm.scaffoldRequest,
                                  "existingTagNumber",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-2 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.notesStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "notesform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "justify-space-between pb-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-requests.notes"
                                          ),
                                          disabled: _vm.processing,
                                          rules: _vm.scaffoldRequestRules.notes,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value: _vm.scaffoldRequest.notes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "notes",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldRequest.notes"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-2 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.optionsStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "optionsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "mx-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.work-shift-days"
                                          ),
                                          disabled: _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.hasDayShift,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "hasDayShift",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.hasDayShift"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.work-shift-nights"
                                          ),
                                          disabled: _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest.hasNightShift,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "hasNightShift",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.hasNightShift"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.electricity"
                                          ),
                                          disabled: _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .hasEnergizedEquipment,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "hasEnergizedEquipment",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.hasEnergizedEquipment"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.confined-space"
                                          ),
                                          disabled: _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .requiresConfinedSpacePermit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requiresConfinedSpacePermit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requiresConfinedSpacePermit"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.lockout"
                                          ),
                                          disabled: _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .requiresLockoutProcedure,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "requiresLockoutProcedure",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.requiresLockoutProcedure"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-requests.new.options.walkdown"
                                          ),
                                          disabled:
                                            _vm.$store.state.curEnvironment
                                              .defaultScaffoldRequestRequiresWalkDown ||
                                            _vm.processing,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldRequest
                                              .isWalkdownRequired,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldRequest,
                                              "isWalkdownRequired",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldRequest.isWalkdownRequired"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.photosStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "photosform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c("fd-add-file-button", {
                                        attrs: { disabled: _vm.processing },
                                        on: { change: _vm.selectNewFile }
                                      }),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scaffold-requests.photos-table-listing-tooltip"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "pl-0",
                                        attrs: {
                                          "append-icon": "search",
                                          label: _vm.$t("common.search"),
                                          "single-line": "",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tablesearchfiles,
                                          callback: function($$v) {
                                            _vm.tablesearchfiles = $$v
                                          },
                                          expression: "tablesearchfiles"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "attachments.file-not-photo-alert-message",
                                        [_vm.touchedFileName.toUpperCase()]
                                      ),
                                      dismissible: ""
                                    },
                                    model: {
                                      value: _vm.showPhotoTabAttachmentAlert,
                                      callback: function($$v) {
                                        _vm.showPhotoTabAttachmentAlert = $$v
                                      },
                                      expression: "showPhotoTabAttachmentAlert"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        value: _vm.$t("common.name"),
                                        expression: "$t('common.name')",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:actions.no-sort",
                                        arg: "actions",
                                        modifiers: { "no-sort": true }
                                      }
                                    ],
                                    staticClass: "fd-actions-table",
                                    attrs: {
                                      items: _vm.photoFiles,
                                      search: _vm.tablesearchfiles,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "sort-by": ["name"],
                                      "item-class": item =>
                                        item.name == _vm.touchedFileName
                                          ? "fd-selected-table-row-background"
                                          : "",
                                      "mobile-breakpoint": "0"
                                    },
                                    on: {
                                      "dblclick:row": (e, data) =>
                                        _vm.viewFile(data.item)
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.actions",
                                        fn: function({ item }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-3-actions-column-min-width"
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-eye\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.view")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-pencil\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.edit")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-delete\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.remove"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.attachmentsStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "attachmentsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("input", {
                                            ref: "addFileButton",
                                            staticClass: "d-none",
                                            attrs: {
                                              type: "file",
                                              onclick: "this.value=null;"
                                            },
                                            on: {
                                              change: v =>
                                                _vm.selectNewFile(
                                                  v.target.files[0]
                                                )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "add-fab-button-primary",
                                                            attrs: {
                                                              fab: "",
                                                              elevation: "5",
                                                              depressed:
                                                                _vm.processing,
                                                              disabled:
                                                                _vm.processing,
                                                              color: "new"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.openNewExternalLinkDialog
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "attachments.external-link.menu-title"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: _vm.selectFile
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common.local-file"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffold-requests.files-table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchfiles,
                                              callback: function($$v) {
                                                _vm.tablesearchfiles = $$v
                                              },
                                              expression: "tablesearchfiles"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("fd-alert", {
                                        attrs: {
                                          type: "info",
                                          label: _vm.$t(
                                            "attachments.file-is-photo-alert-message",
                                            [_vm.touchedFileName.toUpperCase()]
                                          ),
                                          dismissible: ""
                                        },
                                        model: {
                                          value:
                                            _vm.showAttachmentTabPhotoAlert,
                                          callback: function($$v) {
                                            _vm.showAttachmentTabPhotoAlert = $$v
                                          },
                                          expression:
                                            "showAttachmentTabPhotoAlert"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                            value: "",
                                            expression: "''",
                                            arg: "icon",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-table-icon-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "show-when-extra-small": true,
                                              "class_fd-actions-cell-edit-only": true
                                            }
                                          }
                                        ],
                                        class: _vm.$vuetify.breakpoint.xsOnly
                                          ? "fd-action-table"
                                          : "fd-actions-table",
                                        attrs: {
                                          items: _vm.nonPhotoAttachments,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "item-class": item =>
                                            item.name == _vm.touchedFileName
                                              ? "fd-selected-table-row-background"
                                              : "",
                                          "mobile-breakpoint": "0"
                                        },
                                        on: {
                                          "dblclick:row": (e, data) =>
                                            _vm.openAttachment(data.item)
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.icon",
                                            fn: function({ item }) {
                                              return [
                                                item.type == "link"
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-link")
                                                    ])
                                                  : item.type == "file" &&
                                                    item.isPhoto
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-camera")
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v("fa-file")
                                                    ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.action",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.canOpenInNew ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openAttachment(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-open-in-new\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.open-in-new-tab"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "table-4-actions-column-min-width"
                                                    ]
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.canOpenInNew ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-open-in-new\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.open-in-new-tab"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item.file
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "save-draft-small",
                            text: "",
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              !_vm.currentUserCanSubmitScaffoldRequests ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDraft }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-draft-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "submit-small",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.submitting,
                            disabled:
                              !_vm.currentUserCanSubmitScaffoldRequests ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitRequest }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.submit")) + "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mb-2 mx-1" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save-draft",
                            text: "",
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              !_vm.currentUserCanSubmitScaffoldRequests ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDraft }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-draft-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "submit",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.submitting,
                            disabled:
                              !_vm.currentUserCanSubmitScaffoldRequests ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitRequest }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.submit")) + "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled: _vm.step == _vm.lastStep,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }