var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "estimatecomponentnewdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("scheduler.estimates.new-component-dialog.title")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-2 fd-status-chip",
                      attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              `work-order-estimates.scaffold-types.${_vm.estimate.scaffoldTypeID}`
                            )
                          ) +
                          " -\n          " +
                          _vm._s(
                            _vm.$t(
                              `work-order-estimates.scaffold-sub-types.${_vm.estimate.scaffoldSubTypeID}`
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "8" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { accordion: "", multiple: "" },
                      model: {
                        value: _vm.panels,
                        callback: function($$v) {
                          _vm.panels = $$v
                        },
                        expression: "panels"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "walkdowns.existing-walkdown.estimate-subheader"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticClass: "no-left-right-bottom-padding",
                              staticStyle: { "background-color": "white" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "ml-0 mr-0 fd-primary-context-qualifier-background",
                                  attrs: { align: "center", justify: "end" }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "pr-3",
                                    attrs: { label: "Is Designed" },
                                    model: {
                                      value: _vm.isDesigned,
                                      callback: function($$v) {
                                        _vm.isDesigned = $$v
                                      },
                                      expression: "isDesigned"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isDesigned
                                ? _c("sp-lwh-estimate-form", {
                                    ref: "lwhform",
                                    attrs: {
                                      estimate: _vm.estimate,
                                      "show-header": false
                                    },
                                    on: {
                                      close: _vm.closeDialog,
                                      "update:processing": p =>
                                        (_vm.processing = p),
                                      change: _vm.recalculateTimes
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isDesigned
                                ? _c(
                                    "v-container",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { fluid: "" }
                                    },
                                    [
                                      _c("sp-designed-estimate-form", {
                                        attrs: {
                                          bCountString: _vm.bCountString
                                        },
                                        on: {
                                          "update:bCountString": function(
                                            $event
                                          ) {
                                            _vm.bCountString = $event
                                          },
                                          "update:b-count-string": function(
                                            $event
                                          ) {
                                            _vm.bCountString = $event
                                          },
                                          close: _vm.closeDialog
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "py-3",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "secondary"
                                              },
                                              on: { click: _vm.showPartsDialog }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _vm.$t(
                                                        "work-order-estimates.new-estimate.build-bcount-button-label"
                                                      )
                                                    : _vm.$t(
                                                        "work-order-estimates.new-estimate.build-bcount-button-label-short"
                                                      )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("work-order-estimates.modifiers.label")
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticClass: "no-left-right-bottom-padding",
                              staticStyle: { "background-color": "white" }
                            },
                            [
                              _c("sp-estimate-modifiers-form", {
                                attrs: {
                                  processing: _vm.processing,
                                  "show-header": false,
                                  scaffoldIsHardBarricade:
                                    _vm.scaffoldIsHardBarricade,
                                  scaffoldTypeModifierID:
                                    _vm.estimate.scaffoldTypeModifierID,
                                  scaffoldDistanceModifierID:
                                    _vm.estimate.scaffoldDistanceModifierID,
                                  scaffoldElevationModifierID:
                                    _vm.estimate.scaffoldElevationModifierID,
                                  scaffoldHeightModifierID:
                                    _vm.estimate.scaffoldHeightModifierID,
                                  buildDismantleRatioID:
                                    _vm.estimate.buildDismantleRatioID,
                                  scaffoldCongestionFactorID:
                                    _vm.estimate.scaffoldCongestionFactorID,
                                  internalModifierID:
                                    _vm.estimate.internalModifierID,
                                  hoardingModifierID:
                                    _vm.estimate.hoardingModifierID,
                                  modificationPercent: _vm.modificationPercent,
                                  supervisionPercent: _vm.supervisionPercent,
                                  logisticsPercent: _vm.logisticsPercent,
                                  allScaffoldDistances:
                                    _vm.allScaffoldDistances,
                                  allScaffoldElevations:
                                    _vm.allScaffoldElevations,
                                  allScaffoldHeights: _vm.allScaffoldHeights,
                                  allBuildDismantleRatios:
                                    _vm.allBuildDismantleRatios,
                                  allScaffoldCongestionFactors:
                                    _vm.allScaffoldCongestionFactors,
                                  allInternalModifiers:
                                    _vm.allInternalModifiers,
                                  allHoardingModifiers: _vm.allHoardingModifiers
                                },
                                on: {
                                  "update:scaffoldTypeModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldTypeModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffold-type-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldTypeModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffoldDistanceModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldDistanceModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffold-distance-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldDistanceModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffoldElevationModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldElevationModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffold-elevation-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldElevationModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffoldHeightModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldHeightModifierID",
                                      $event
                                    )
                                  },
                                  "update:scaffold-height-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldHeightModifierID",
                                      $event
                                    )
                                  },
                                  "update:buildDismantleRatioID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "buildDismantleRatioID",
                                      $event
                                    )
                                  },
                                  "update:build-dismantle-ratio-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "buildDismantleRatioID",
                                      $event
                                    )
                                  },
                                  "update:scaffoldCongestionFactorID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldCongestionFactorID",
                                      $event
                                    )
                                  },
                                  "update:scaffold-congestion-factor-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "scaffoldCongestionFactorID",
                                      $event
                                    )
                                  },
                                  "update:internalModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "internalModifierID",
                                      $event
                                    )
                                  },
                                  "update:internal-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "internalModifierID",
                                      $event
                                    )
                                  },
                                  "update:hoardingModifierID": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "hoardingModifierID",
                                      $event
                                    )
                                  },
                                  "update:hoarding-modifier-i-d": function(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.estimate,
                                      "hoardingModifierID",
                                      $event
                                    )
                                  },
                                  "update:modificationPercent": function(
                                    $event
                                  ) {
                                    _vm.modificationPercent = $event
                                  },
                                  "update:modification-percent": function(
                                    $event
                                  ) {
                                    _vm.modificationPercent = $event
                                  },
                                  "update:supervisionPercent": function(
                                    $event
                                  ) {
                                    _vm.supervisionPercent = $event
                                  },
                                  "update:supervision-percent": function(
                                    $event
                                  ) {
                                    _vm.supervisionPercent = $event
                                  },
                                  "update:logisticsPercent": function($event) {
                                    _vm.logisticsPercent = $event
                                  },
                                  "update:logistics-percent": function($event) {
                                    _vm.logisticsPercent = $event
                                  },
                                  change: _vm.recalculateTimes
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.xs
                        ? _c(
                            "v-expansion-panel",
                            { staticClass: "secondary-section-style" },
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("common.summary")) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticClass: "no-left-right-bottom-padding",
                                  staticStyle: { "background-color": "white" }
                                },
                                [
                                  _c("fd-work-order-estimate-summary-panel", {
                                    attrs: {
                                      "current-estimate": _vm.estimate,
                                      "show-header": false,
                                      "show-actuals": false,
                                      loading: _vm.loadingEstimateTimes,
                                      currentEstimateTimeTotals:
                                        _vm.estimateTotalTimes,
                                      workOrderActualsEstimatedTimes: {},
                                      workOrderActualWorkedTimes: {}
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-col",
                    { attrs: { cols: "6", md: "4" } },
                    [
                      _c("fd-work-order-estimate-summary-panel", {
                        attrs: {
                          "current-estimate": _vm.estimate,
                          "show-header": false,
                          "show-actuals": false,
                          loading: _vm.loadingEstimateTimes,
                          currentEstimateTimeTotals: _vm.estimateTotalTimes,
                          workOrderActualsEstimatedTimes: {},
                          workOrderActualWorkedTimes: {}
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              false
                ? _c("v-btn", { on: { click: _vm.recalculateTimes } }, [
                    _vm._v("Recalc")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.processing,
                    small:
                      _vm.$vuetify.breakpoint.xsOnly &&
                      _vm.$vuetify.breakpoint.width >= 360,
                    "x-small": _vm.$vuetify.breakpoint.width < 360
                  },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "savebutton",
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }