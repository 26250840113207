import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  WorkOrderStatuses,
  WorkOrderStatusDetails,
  WorkOrderForSchedulerGrid,
  Tag,
  reportService,
  ContractorWithTags,
  Discipline,
  ProjectLocation,
  ScaffoldRequestTypes,
  disciplineService,
  projectLocationService,
  personService,
  contractorService,
  WorkOrderWithAllDetails
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { valueInArray } from "@fd/lib/client-util/array";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import printBlob from "@fd/lib/client-util/printBlob";
import { VDataTable } from "@fd/lib/vue/types";
import { filterByTags } from "../services/taggableItems";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "../views/components/ActiveWorkForScaffoldDialog.vue";
import { PersonWithName, GetPersonName, SortItemsWithName } from "../utils/person";
import personDataStore from "../store/referenceData/person";
import contractorDataStore from "../store/referenceData/contractor";
import { WorkOrderDetails } from "../views/components/WorkOrderDetailsForm.vue";

export type WorkOrderWithExtraDetails = WorkOrderDetails &
  WorkOrderWithAllDetails & {
    cachedIsUrgent: boolean; // Used to track the isUrgent property value on load.  This will prevent the record from jumping away when a user changes the urgency property
    isWalkdownPlanned: boolean;
    isWorkPlanned: boolean;
    formattedStartDate: string;
    formattedRequiredDate: string;
    formattedCompletedDate: string;
    workPackageNames: string[] | undefined;
    requiredDatePushed: number | undefined;
  };
export function CalculateRequiredDateDaysPushed(
  requiredDate: Date | null | undefined,
  approvedRequiredDate: Date | null | undefined
): number {
  let daysPushed = 0;
  if (
    !!approvedRequiredDate &&
    !!requiredDate &&
    requiredDate.getTime() != approvedRequiredDate.getTime()
  ) {
    daysPushed = Math.round((requiredDate.getTime() - approvedRequiredDate.getTime()) / 86400000);
  }
  return daysPushed;
}
export function ParseWorkOrderWithAllDetails(
  workOrder: WorkOrderWithAllDetails | WorkOrderForSchedulerGrid
): WorkOrderWithExtraDetails {
  workOrder = workOrder as WorkOrderWithAllDetails;
  return {
    ...workOrder,
    requiredDatePushed: CalculateRequiredDateDaysPushed(
      workOrder.requiredDate,
      workOrder.approvedRequiredDate
    ),
    progress: !!workOrder.progress
      ? Math.max(Math.min(100, Math.round(workOrder.progress / 5) * 5), 0)
      : 0, // The progress picker is in increments of 5 so round to the nearest 5
    priority: !!workOrder.priority && workOrder.priority > 0 ? Math.min(workOrder.priority, 5) : 5,
    formattedStartDate: !!workOrder.startDate
      ? DateUtil.stripTimeFromLocalizedDateTime(workOrder.startDate)
      : "",
    formattedRequiredDate: !!workOrder.requiredDate
      ? DateUtil.stripTimeFromLocalizedDateTime(workOrder.requiredDate)
      : "",
    formattedCompletedDate: !!workOrder.completedDate
      ? DateUtil.localizedDateTimeString(workOrder.completedDate)
      : "",
    workPackageNames: workOrder.workPackages?.map(
      x => (x.name ?? "") + " | " + (x.activityID ?? "")
    ),
    isWalkdownPlanned: !!workOrder.plannedWalkdownStartDate,
    isWorkPlanned: !!workOrder.plannedWorkStartDate,
    cachedIsUrgent: workOrder.isUrgent ?? false,
    requestType: workOrder.scaffoldRequestType,
    requestSubType: workOrder.scaffoldRequestSubType
  };
}

export function WorkForScaffoldDetailsFromWorkOrder(
  workOrder: WorkOrderWithExtraDetails
): WorkForScaffoldDetails {
  return {
    ...workOrder,
    approvalComments: "",
    workType: "workorder",
    workOrderId: workOrder.id,
    scaffoldRequestId: workOrder.scaffoldRequestID,
    requestType: workOrder.scaffoldRequestType,
    requestSubType: workOrder.scaffoldRequestSubType,
    displayIdentifier: `WO-${workOrder.internalNumber}`,
    archivedDate: workOrder.archivedDate,
    isArchived: workOrder.isArchived,
    currentUserWorkOrderPermissions: workOrder.currentUserPermissions,
    currentUserRequestPermissions: undefined
  };
}

function CanSelectWorkOrderStatus(
  workOrderStatus: WorkOrderStatuses,
  currentStatus: WorkOrderStatuses | undefined,
  requestType: ScaffoldRequestTypes | undefined,
  currentUserCanEditWorkOrderStatus: boolean,
  currentUserCanCancelWorkOrder: boolean,
  currentUserCanEditWorkOrderProgress: boolean
): boolean {
  if (!currentUserCanEditWorkOrderStatus) return false;

  if (workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration) return false;

  // Approved can never be selected
  if (workOrderStatus == WorkOrderStatuses.Approved) return false;

  // Walkdown can be selected only if the work order is currently approved, or can be reversed if in In Scheduling
  if (workOrderStatus == WorkOrderStatuses.Walkdown)
    return (
      (requestType == ScaffoldRequestTypes.Erect ||
        requestType == ScaffoldRequestTypes.Dismantle ||
        requestType == ScaffoldRequestTypes.Modify) &&
      (currentStatus == WorkOrderStatuses.Approved ||
        currentStatus == WorkOrderStatuses.InScheduling)
    );

  // Estimated can never be selected
  if (workOrderStatus == WorkOrderStatuses.Estimated) return false;

  // InScheduling can be selected if it was in ON HOLD status
  if (workOrderStatus == WorkOrderStatuses.InScheduling)
    return currentStatus == WorkOrderStatuses.OnHold;

  // Started or Completed can be selected only if the user has permission to modify a work order's progress
  if (
    workOrderStatus == WorkOrderStatuses.Started ||
    workOrderStatus == WorkOrderStatuses.Completed
  )
    return currentUserCanEditWorkOrderProgress;

  // Cancelled can be selected only if the user has permission to cancel work orders
  if (workOrderStatus == WorkOrderStatuses.Cancelled) return currentUserCanCancelWorkOrder;

  return true;
}
type WorkOrderStatusDetailsAndDisabled = WorkOrderStatusDetails & {
  disabled: boolean;
};

export type FilteringRequestorContext = "all" | "mine";
export type FilteringUnassignedContext = boolean;
export type FilteringAssignedContext = boolean;
export type FilteringContext = {
  requestorFilter: FilteringRequestorContext;
  unassignedFilter: FilteringUnassignedContext;
  assignedFilter: FilteringAssignedContext;
  showScaffoldRequests: boolean;
  showPaintRequests: boolean;
  showMaintenanceRequests: boolean;
  showInsulationRequests: boolean;
};

export default FDVue.extend({
  mixins: [userAccess, serviceErrorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("../views/components/WorkOrderDetailsForm.vue"),
    "fd-scaffold-number-with-badges": () =>
      import("../views/components/ScaffoldNumberWithBadges.vue")
  },

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,
      lastTableSortContext: "" as string,

      allWorkOrders: [] as WorkOrderWithExtraDetails[],

      // dev flag to show/hide the top row of filters which includes a Request Type dropdown
      showRequestTypeFilter: false,

      allRequestTypes: (Object.keys(ScaffoldRequestTypes)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: ScaffoldRequestTypes[x]
        };
      }),

      allContractors: [] as ContractorWithTags[],
      scaffoldContractors: [] as ContractorWithTags[],
      paintContractors: [] as ContractorWithTags[],
      maintenanceContractors: [] as ContractorWithTags[],
      insulationContractors: [] as ContractorWithTags[],
      allWorkOrderStatuses: [] as WorkOrderStatusDetails[],
      allStatusNames: [] as string[],
      allCoordinators: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      allDisciplines: [] as Discipline[],
      currentUserDisciplines: [] as Discipline[],
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      priorityValues: Array.from(Array(5).keys()).map(x => x + 1),
      progressValues: Array.from(Array(101).keys()).filter(x => x % 5 == 0),

      archivedLoading: false,
      updatingWorkOrderID: undefined as string | null | undefined,

      // The following tracks the current width of the browser window. It works in conjunction with a EventListener
      // setup in the "created" hook.
      windowWidth: 0,
      windowHeight: 0,
      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    canFilterRequestType(): boolean {
      return (
        !!this.curUserAccess.canViewMaintenanceJobs ||
        !!this.curUserAccess.canViewPaintJobs ||
        !!this.curUserAccess.canViewInsulationJobs
      );
    },
    dateformat() {
      //First check to make sure the registered width and height have a value larger than zero other wise something strange is up.
      if (this.windowWidth > 0 && this.windowHeight > 0) {
        if (this.windowWidth > this.windowHeight) {
          return "llll";
        } else {
          //Potentially a PORTRAIT oriented mobile device.
          if (this.windowWidth < 769) {
            return "lll";
          } else {
            return "llll";
          }
        }
      } else {
        return "llll";
      }
    },
    assignedWorkOrders(): WorkOrderWithExtraDetails[] {
      let filteredWorkOrders = this.baseFilteredWorkOrders;

      // filter out requests which are not fully assigned and planned
      // showWorkOrderOnToDo is used since this state is dependant on the work order's status
      filteredWorkOrders = filteredWorkOrders.filter(
        x => !!x.foremanID && !!x.generalForemanID && !!this.showWorkOrderOnToDo(x)
      );

      return filteredWorkOrders;
    },
    assignedWorkOrdersCount(): string {
      return `${this.assignedWorkOrders.length}`;
    },
    unassignedWorkOrders(): WorkOrderWithExtraDetails[] {
      let filteredWorkOrders = this.baseFilteredWorkOrders;

      // filter out requests which are fully assigned and planned
      // showWorkOrderOnToDo is used since this state is dependant on the work order's status
      filteredWorkOrders = filteredWorkOrders.filter(
        x => !x.foremanID || !x.generalForemanID || !this.showWorkOrderOnToDo(x)
      );
      return filteredWorkOrders;
    },
    unassignedWorkOrdersCount(): string {
      return `${this.unassignedWorkOrders.length}`;
    },
    baseFilteredWorkOrders(): WorkOrderWithExtraDetails[] {
      var filteredWorkOrders = this.allWorkOrders;
      if (!this.showArchived) {
        // Some actions will archive the WO when the user updates it, which means it won't be returned on the next load
        // Hide it client side until that next load happens
        filteredWorkOrders = filteredWorkOrders.filter(x => !x.isArchived);
      }
      if (this.requestorFilterIsMine) {
        // returns requests either submitted by the current user, or on the current user's behalf
        filteredWorkOrders = filteredWorkOrders.filter(
          x =>
            (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
            (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
            (!!x.foremanID && x.foremanID == this.curUserID)
        );
      }
      if (this.selectedRequestTypes.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.scaffoldRequestType, this.selectedRequestTypes)
        );
      }
      if (this.selectedStatusNames.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.workOrderStatusName, this.selectedStatusNames)
        );
      }
      if (this.selectedContractors.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.assignedContractorID, this.selectedContractors)
        );
      }
      if (this.selectedDisciplines.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.disciplineID, this.selectedDisciplines)
        );
      }
      if (this.selectedForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.foremanID, this.selectedForemanIDs)
        );
      }
      if (this.selectedGeneralForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.generalForemanID, this.selectedGeneralForemanIDs)
        );
      }
      if (this.selectedAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (this.selectedSubAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }
      return filterByTags(this.tagsSelectedForFiltering, filteredWorkOrders);
    },
    filteredWorkOrders(): WorkOrderWithExtraDetails[] {
      let filteredWorkOrders = this.baseFilteredWorkOrders;
      if (!this.showScaffoldRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo =>
            wo.scaffoldRequestType != ScaffoldRequestTypes.Erect &&
            wo.scaffoldRequestType != ScaffoldRequestTypes.Modify &&
            wo.scaffoldRequestType != ScaffoldRequestTypes.Dismantle
        );
      }
      if (!this.showMaintenanceRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Maintenance
        );
      }
      if (!this.showPaintRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Paint
        );
      }
      if (!this.showInsulationRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Insulation
        );
      }
      if (!this.includeUnassigned) {
        // filter out requests which are not fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !!x.foremanID && !!x.generalForemanID && !!this.showWorkOrderOnToDo(x)
        );
      }
      if (!this.includeAssigned) {
        // filter out requests which are fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !x.foremanID || !x.generalForemanID || !this.showWorkOrderOnToDo(x)
        );
      }
      return filteredWorkOrders;
    },
    visibleToDoWorkOrders(): WorkOrderWithExtraDetails[] {
      return this.visibleWorkOrders.filter(x => this.showWorkOrderOnToDo(x));
    },
    visibleWorkOrders(): WorkOrderWithExtraDetails[] {
      // This is a hack because the contractors list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      var filteredWorkOrders = this.filteredWorkOrders;

      if (!!this.tablesearch?.length) {
        let dataTable = this.$refs.datatable as VDataTable;
        if (!!dataTable) {
          filteredWorkOrders = dataTable.customFilterWithColumns(
            filteredWorkOrders,
            this.tablesearch
          );
        }
      }
      return filteredWorkOrders;
    },

    requestorFilter: {
      get(): FilteringRequestorContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.requestorFilter ?? "all";
      },
      set(val: FilteringRequestorContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.requestorFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },

    filteringContext: {
      get(): FilteringContext {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    requestorFilterIsMine: {
      get(): boolean {
        return this.requestorFilter == "mine";
      },
      set(val: boolean) {
        this.requestorFilter = val ? "mine" : "all";
      }
    },
    showScaffoldRequests: {
      get(): boolean {
        return this.filteringContext.showScaffoldRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showScaffoldRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showMaintenanceRequests: {
      get(): boolean {
        return this.filteringContext.showMaintenanceRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showMaintenanceRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showPaintRequests: {
      get(): boolean {
        return this.filteringContext.showPaintRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showPaintRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showInsulationRequests: {
      get(): boolean {
        return this.filteringContext.showInsulationRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showInsulationRequests = val;
        this.filteringContext = filteringContext;
      }
    },

    unassignedFilter: {
      get(): FilteringUnassignedContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.unassignedFilter;
      },
      set(val: FilteringUnassignedContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.unassignedFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    includeUnassigned: {
      get(): boolean {
        return this.unassignedFilter;
      },
      set(val: boolean) {
        this.unassignedFilter = val;
      }
    },

    assignedFilter: {
      get(): FilteringAssignedContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.assignedFilter;
      },
      set(val: FilteringAssignedContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.assignedFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    includeAssigned: {
      get(): boolean {
        return this.assignedFilter;
      },
      set(val: boolean) {
        this.assignedFilter = val;
      }
    },

    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    canViewDisciplinesFilter(): boolean {
      return (
        !!this.currentUserDisciplines &&
        (this.currentUserDisciplines.length != 1 || this.selectableDisciplines.length > 1)
      );
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allWorkOrders);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        let currentProcessing = this.processing;
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.loadWorkOrders();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.loadWorkOrders();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    selectableFilterStatuses(): any[] {
      return this.allStatusNames.map(x => {
        return {
          name: x,
          count: this.allWorkOrders.filter(wo => wo.workOrderStatusName == x).length
        };
      });
    },

    selectedStatusNames: {
      get(): string[] {
        var selectedStatusNames = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.statusesForFiltering as string[];
        // selectedStatusNames = selectedStatusNames.filter(x =>
        //   valueInArray(x, this.selectableStatusNames)
        // );
        return selectedStatusNames;
      },
      set(val: string[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    },

    selectableDisciplines(): Discipline[] {
      let selectableDisciplineIDs = this.allWorkOrders
        .filter(x => !!x.disciplineID)
        .map(x => x.disciplineID!);
      selectableDisciplineIDs = [...new Set(selectableDisciplineIDs)];
      return this.allDisciplines.filter(x => selectableDisciplineIDs.includes(x.id!));
    },

    selectedDisciplines: {
      get(): string[] {
        var selectedDisciplines = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.disciplinesForFiltering as string[];
        selectedDisciplines = selectedDisciplines.filter(x =>
          valueInArray(
            x,
            this.selectableDisciplines.map(x => x.id!)
          )
        );
        return selectedDisciplines;
      },
      set(val: string[]) {
        this.$store.commit("SET_DISCIPLINES_FOR_FILTERING", val);
      }
    },

    selectableGeneralForemen(): PersonWithName[] {
      let selectableGeneralForemanIDs = this.allWorkOrders
        .filter(x => !!x.generalForemanID)
        .map(x => x.generalForemanID!);
      selectableGeneralForemanIDs = [...new Set(selectableGeneralForemanIDs)];

      return this.allGeneralForemen.filter(x => valueInArray(x.id, selectableGeneralForemanIDs));
    },

    selectedGeneralForemanIDs: {
      get(): string[] {
        var selectedGeneralForemanIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.generalForemanIDsForFiltering as string[];
        selectedGeneralForemanIDs = selectedGeneralForemanIDs.filter(x =>
          valueInArray(
            x,
            this.selectableGeneralForemen.map(x => x.id!)
          )
        );
        return selectedGeneralForemanIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_GENERAL_FOREMAN_IDS_FOR_FILTERING", val);
      }
    },

    selectableForemen(): PersonWithName[] {
      let selectableForemanIDs = this.allWorkOrders
        .filter(x => !!x.foremanID)
        .map(x => x.foremanID!);
      selectableForemanIDs = [...new Set(selectableForemanIDs)];

      return this.allForemen.filter(x => valueInArray(x.id, selectableForemanIDs));
    },

    selectedForemanIDs: {
      get(): string[] {
        var selectedForemanIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.foremanIDsForFiltering as string[];
        selectedForemanIDs = selectedForemanIDs.filter(x =>
          valueInArray(
            x,
            this.selectableForemen.map(x => x.id!)
          )
        );
        return selectedForemanIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_FOREMAN_IDS_FOR_FILTERING", val);
      }
    },

    selectableAreas(): ProjectLocation[] {
      let selectableAreaIDs = this.allWorkOrders.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => valueInArray(x.id, selectableAreaIDs));
    },

    selectedAreas: {
      get(): string[] {
        var selectedAreas = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering as string[];
        selectedAreas = selectedAreas.filter(x =>
          valueInArray(
            x,
            this.selectableAreas.map(x => x.id!)
          )
        );
        return selectedAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): ProjectLocation[] {
      let selectableSubAreaIDs = this.allWorkOrders
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          valueInArray(x.parentLocationID, selectedAreaIDs) &&
          valueInArray(x.id, selectableSubAreaIDs)
      );
    },

    selectedSubAreas: {
      get(): string[] {
        var selectedSubAreas = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering as string[];
        selectedSubAreas = selectedSubAreas.filter(x =>
          valueInArray(
            x,
            this.selectableSubAreas.map(x => x.id!)
          )
        );
        return selectedSubAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectableContractors(): ContractorWithTags[] {
      let selectableContractorIDs = this.allWorkOrders.map(x => x.assignedContractorID);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return this.allContractors.filter(x => selectableContractorIDs.includes(x.id));
    },

    selectedContractors: {
      get(): string[] {
        var selectedContractors = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering as string[];
        selectedContractors = selectedContractors.filter(x =>
          valueInArray(
            x,
            this.selectableContractors.map(x => x.id!)
          )
        );
        return selectedContractors;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    selectedRequestTypes: {
      get(): number[] {
        var selectedRequestTypes = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.requestTypesForFiltering as number[];
        selectedRequestTypes = selectedRequestTypes.filter(x =>
          valueInArray(
            x,
            this.allRequestTypes.map(x => x.value!)
          )
        );
        return selectedRequestTypes;
      },
      set(val: number[]) {
        this.$store.commit("SET_REQUEST_TYPES_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    assignableContractorsForType(type: ScaffoldRequestTypes): ContractorWithTags[] {
      if (type == ScaffoldRequestTypes.Maintenance) {
        return this.maintenanceContractors;
      } else if (type == ScaffoldRequestTypes.Paint) {
        return this.paintContractors;
      } else if (type == ScaffoldRequestTypes.Insulation) {
        return this.insulationContractors;
      } else {
        return this.scaffoldContractors;
      }
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadWorkOrders();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadWorkOrders() {},

    workOrderCanBeCancelled(item: WorkOrderWithExtraDetails): boolean {
      return item.currentUserPermissions.canCancel;
    },

    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    urgencyRowBackground(item: any) {
      return item.isUrgent ? "fd-urgent-table-row-background" : "";
    },

    customSort(
      items: WorkOrderWithExtraDetails[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "editablePriority") {
        propName = "priority";
      } else if (propName === "editableProgress") {
        propName = "progress";
      } else if (propName === "formattedRequiredDate") {
        propName = "requiredDate";
      } else if (propName === "generalForeman") {
        propName = "generalForemanName";
      } else if (propName === "foreman") {
        propName = "foremanName";
      } else if (propName === "workOrderStatusName") {
        propName = "workOrderStatus";
      }

      // Track the last-used sort context
      // If the two are the same, we want to leave the cached data as-is so the item sorting doesn't change
      // If the two are different, we can ignore & reset the cached properties since the user is manually changing the sort
      if (propName != this.lastTableSortContext) {
        // Since cached values are used for sorting, and they only get updated programatically, we need to update them if the user manually sorts
        items.forEach(x => {
          x.cachedIsUrgent = x.isUrgent ?? false;
        });
      }

      items.sort((a: WorkOrderWithExtraDetails, b: WorkOrderWithExtraDetails) => {
        // No matter how we sort, we want the urgent at the top
        let urgentA = a.cachedIsUrgent ?? false;
        let urgentB = b.cachedIsUrgent ?? false;
        if (urgentA != urgentB) {
          return urgentA ? -1 : 1;
        }
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (propName == "editablePriority" || propName == "priority") {
          if (a.cachedIsUrgent) val1 = 0;
          if (b.cachedIsUrgent) val2 = 0;
        } else if (propName == "todo") {
          // since the "todo" column uses a different value for each row, the prop name for each item being compared may be different.
          if (this.workOrderNeedsWalkdown(a)) {
            val1 = a.isWalkdownPlanned;
          } else {
            val1 = a.isWorkPlanned;
          }

          if (this.workOrderNeedsWalkdown(b)) {
            val2 = b.isWalkdownPlanned;
          } else {
            val2 = b.isWorkPlanned;
          }
        }
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });

      this.lastTableSortContext = propName;
      return items;
    },

    async downloadAndPrintPlannerReport(showOnlyToDos: boolean, reportType: string = "pdf") {
      this.processing = true;
      try {
        var workOrdersToPrint = this.visibleWorkOrders;
        if (showOnlyToDos) {
          workOrdersToPrint = this.visibleToDoWorkOrders;
        }
        if (workOrdersToPrint.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("scheduler.printing.no-data-message"),
            type: "info"
          });
          return;
        }

        workOrdersToPrint = workOrdersToPrint.sort((a, b) => {
          // No matter how we sort, we want the urgent at the top
          let urgentA = a.isUrgent ?? false;
          let urgentB = b.isUrgent ?? false;
          if (urgentA != urgentB) {
            return urgentA ? -1 : 1;
          }

          // Urgency is the same, sort by priority ascending, with the lowest number at the top
          var priorityA = a.priority ?? 5;
          var priorityB = b.priority ?? 5;
          if (priorityA != priorityB) {
            return priorityA - priorityB;
          }

          // Priority of the items are the same, sort by required date ascing with the earliest required at the top
          // A lack of required date means it's not required and so goes to the bottom
          var requiredA = a.requiredDate ?? new Date(2999, 12, 31);
          var requiredB = a.requiredDate ?? new Date(2999, 12, 31);
          if (requiredA.getTime() != requiredB.getTime()) {
            return requiredA.getTime() - requiredB.getTime();
          }

          return 0;
        });

        var contractorFilterValue = !!this.selectedContractors?.length
          ? this.allContractors
              .filter(x => valueInArray(x.id!, this.selectedContractors))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var disciplineFilterValue = !!this.selectedDisciplines?.length
          ? this.allDisciplines
              .filter(x => valueInArray(x.id!, this.selectedDisciplines))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var areaFilterValue = !!this.selectedAreas?.length
          ? this.allAreas
              .filter(x => valueInArray(x.id!, this.selectedAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var subAreaFilterValue = !!this.selectedSubAreas?.length
          ? this.allSubAreas
              .filter(x => valueInArray(x.id!, this.selectedSubAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var generalForemanFilterValue = !!this.selectedGeneralForemanIDs?.length
          ? this.allGeneralForemen
              .filter(x => valueInArray(x.id!, this.selectedGeneralForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var foremanFilterValue = !!this.selectedForemanIDs?.length
          ? this.allForemen
              .filter(x => valueInArray(x.id!, this.selectedForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var statusFilterValue = !!this.selectedStatusNames?.length
          ? this.allStatusNames.filter(x => valueInArray(x, this.selectedStatusNames)).join(", ")
          : `${this.$t("common.all")}`;

        var unassignedSelected = this.includeUnassigned
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var assignedSelected = this.includeAssigned
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var onlyMineSelected = this.requestorFilterIsMine
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;
        var showOnlyToDosValue = showOnlyToDos
          ? `${this.$t("common.yes")}`
          : `${this.$t("common.no")}`;

        var blob = await reportService.getPlannerPrintoutReportContentWithData(
          workOrdersToPrint,
          reportType,
          contractorFilterValue,
          disciplineFilterValue,
          areaFilterValue,
          subAreaFilterValue,
          generalForemanFilterValue,
          foremanFilterValue,
          statusFilterValue,
          assignedSelected,
          unassignedSelected,
          onlyMineSelected,
          showOnlyToDosValue,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "planner-printout.xlsx");
        } else {
          printBlob(blob, "planner-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    workOrderScaffoldOtherActiveWorkOrderIDs(workOrder: WorkOrderWithExtraDetails): string[] {
      let scaffoldWorkOrderIDs = workOrder.scaffoldActiveWorkOrderIDs ?? [];
      let otherWorkOrderIDs = scaffoldWorkOrderIDs.filter(x => x != workOrder.id);
      return otherWorkOrderIDs;
    },

    workOrderScaffoldHasPotentialConflict(workOrder: WorkOrderWithExtraDetails): boolean {
      let isDismantle = workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle;

      let scaffoldSubmittedRequestIDs = workOrder.scaffoldSubmittedRequestIDs ?? [];
      let scaffoldDismantleRequestIDs = workOrder.scaffoldDismantleRequestIDs ?? [];
      let allDismantleWorkOrderIDs = workOrder.scaffoldDismantleWorkOrderIDs ?? [];
      let allActiveWorkOrderIDs = workOrder.scaffoldActiveWorkOrderIDs ?? [];

      let otherActiveWorkOrderIDs = this.workOrderScaffoldOtherActiveWorkOrderIDs(workOrder);
      let otherScaffoldRequestIDs =
        scaffoldSubmittedRequestIDs?.filter(x => x != workOrder.scaffoldRequestID) ?? [];
      let hasOtherWork = otherActiveWorkOrderIDs.length > 0 || otherScaffoldRequestIDs.length > 0;

      let nonDismantleRequestIDs = scaffoldSubmittedRequestIDs.filter(
        x => !scaffoldDismantleRequestIDs.includes(x)
      );
      let nonDismantleWorkOrderIDs = allActiveWorkOrderIDs.filter(
        x => !allDismantleWorkOrderIDs.includes(x)
      );

      let currentIsDismantleWithOtherWork = isDismantle && hasOtherWork;

      let totalDismantleItemCount =
        allDismantleWorkOrderIDs.length + scaffoldDismantleRequestIDs.length;
      let scaffoldHasMultipleDismantles = totalDismantleItemCount > 1;

      let scaffoldHasActiveDismantleWithOtherWork =
        totalDismantleItemCount > 0 &&
        (nonDismantleRequestIDs.length > 0 || nonDismantleWorkOrderIDs.length > 0);
      return (
        currentIsDismantleWithOtherWork ||
        scaffoldHasMultipleDismantles ||
        scaffoldHasActiveDismantleWithOtherWork
      );
    },

    workOrderScaffoldHasOtherActiveWorkOrders(workOrder: WorkOrderWithExtraDetails): boolean {
      return (
        !!workOrder.scaffoldID &&
        this.workOrderScaffoldOtherActiveWorkOrderIDs(workOrder).length > 0
      );
    },

    // *** SCAFF BADGE COUNTS ***

    workOrderRequestsBadgeCount(workOrder: WorkOrderWithExtraDetails): number {
      return workOrder.scaffoldSubmittedRequestIDs?.length ?? 0;
    },

    workOrderOtherCount(workOrder: WorkOrderWithExtraDetails): number {
      return this.workOrderScaffoldOtherActiveWorkOrderIDs(workOrder).length;
    },

    workOrderCountBadgeCount(workOrder: WorkOrderWithExtraDetails): number {
      return workOrder.scaffoldActiveWorkOrderIDs?.length ?? 0;
    },

    workOrderDismantleBadgeCount(workOrder: WorkOrderWithExtraDetails): number {
      return workOrder.scaffoldDismantleWorkOrderIDs?.length ?? 0;
    },

    // *** STATUS COMPARISONS ***

    workOrderIsOnHold(workOrder: WorkOrderWithExtraDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.OnHold;
    },

    workOrderIsCancelled(workOrder: WorkOrderWithExtraDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Cancelled;
    },

    workOrderIsInScheduling(workOrder: WorkOrderWithExtraDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.InScheduling;
    },

    workOrderIsStarted(workOrder: WorkOrderWithExtraDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Started;
    },

    workOrderNeedsWalkdown(workOrder: WorkOrderWithExtraDetails): boolean {
      return (
        workOrder.workOrderStatus == WorkOrderStatuses.Walkdown ||
        workOrder.workOrderStatus == WorkOrderStatuses.Estimated
      );
    },

    showWorkOrderOnToDo(workOrder: WorkOrderWithExtraDetails): boolean {
      let val = this.workOrderNeedsWalkdown(workOrder)
        ? workOrder.isWalkdownPlanned
        : workOrder.isWorkPlanned;
      return val;
    },

    selectableStatusesForWorkOrder(
      workOrder: WorkOrderWithExtraDetails
    ): WorkOrderStatusDetailsAndDisabled[] {
      return this.allWorkOrderStatuses.map(
        x =>
          ({
            ...x,
            disabled: !CanSelectWorkOrderStatus(
              x.value,
              workOrder.workOrderStatus,
              workOrder.scaffoldRequestType,
              workOrder.currentUserPermissions.canEditStatus,
              workOrder.currentUserPermissions.canCancel,
              workOrder.currentUserPermissions.canEditProgress
            )
          } as WorkOrderStatusDetailsAndDisabled)
      );
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadCurrentUserDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getByPersonID(this.curUserID);
      this.currentUserDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      this.allContractors = await contractorService.getAll(false, null, null);
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = coordinators.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = generalForemen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = foremen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    coordinatorsInContractor(contractorID: string) {
      var coordinatorsForContractor = SortItemsWithName(
        this.allCoordinators.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return coordinatorsForContractor;
    },

    proxyCoordinatorsForContractor(
      assignedContractorID: string,
      homeContractorID: string
    ): PersonWithName[] {
      // Since a proxy contractor is a proxy for all contractors, we don't need to restrict proxy contractors
      var proxyCoordinatorsInContractor = this.coordinatorsInContractor(homeContractorID);
      if (!proxyCoordinatorsInContractor?.length) return [];

      let proxyCoordinatorsForContractor = [] as PersonWithName[];
      proxyCoordinatorsInContractor.forEach(coordinator => {
        if (!!coordinator.includesAllContractors && coordinator.includesAllContractors == true) {
          proxyCoordinatorsForContractor.push(coordinator);
          return;
        }
        if (!coordinator.contractorIDJson) return;

        let coordinatorVisibleContractorIDs = JSON.parse(coordinator.contractorIDJson) as string[];
        if (!coordinatorVisibleContractorIDs?.length) return;
        if (!coordinatorVisibleContractorIDs.includes(assignedContractorID)) return;

        proxyCoordinatorsForContractor.push(coordinator);
      });

      return proxyCoordinatorsForContractor;
    },

    getAssignableCoordinatorsForWorkOrder(item: {
      assignedContractorID: string | null | undefined;
      assignedContractorName?: string | undefined;
      coordinatorID: string | null | undefined;
      coordinatorName?: string | undefined;
      // The data coming in from SCHEDULER has `requestType`, but the items in ESTIMATES doesn't, it has the base WO's `scaffoldRequestType`
      requestType?: ScaffoldRequestTypes | null | undefined;
      scaffoldRequestType?: ScaffoldRequestTypes | null | undefined;
    }): any[] {
      var contractorID = item?.assignedContractorID;
      if (!contractorID) return [];

      var coordinatorsForContractor = this.coordinatorsInContractor(contractorID);

      var typeIsScaffold = false,
        typeIsMaintenance = false,
        typeIsPaint = false,
        typeIsInsulation = false;
      if (
        item.scaffoldRequestType == ScaffoldRequestTypes.Maintenance ||
        item.requestType == ScaffoldRequestTypes.Maintenance
      ) {
        typeIsMaintenance = true;
      } else if (
        item.scaffoldRequestType == ScaffoldRequestTypes.Paint ||
        item.requestType == ScaffoldRequestTypes.Paint
      ) {
        typeIsPaint = true;
      } else if (
        item.scaffoldRequestType == ScaffoldRequestTypes.Insulation ||
        item.requestType == ScaffoldRequestTypes.Insulation
      ) {
        typeIsInsulation = true;
      } else {
        typeIsScaffold = true;
      }

      var otherCoordinators: ({ header: string } | { divider: boolean } | PersonWithName)[] = [];
      var proxyContractors = this.allContractors.filter(
        x =>
          x.id != contractorID &&
          x.canActAsProxy == true &&
          (!typeIsScaffold || !!x.isScaffoldCompany) &&
          (!typeIsMaintenance || !!x.isMaintenanceCompany) &&
          (!typeIsPaint || !!x.isPaintCompany) &&
          (!typeIsInsulation || !!x.isInsulationCompany)
      );
      if (proxyContractors.length > 0) {
        proxyContractors.forEach(x => {
          var proxyCoordinators = this.proxyCoordinatorsForContractor(contractorID!, x.id!);
          if (proxyCoordinators.length == 0) return;

          if (otherCoordinators.length > 0) {
            otherCoordinators.push({
              divider: true
            });
          }
          otherCoordinators.push({
            header: x.name ?? ""
          });
          otherCoordinators = otherCoordinators.concat(proxyCoordinators);
        });
        if (!!coordinatorsForContractor?.length && !!otherCoordinators?.length) {
          otherCoordinators.push({ divider: true });
          var assignedContractorName =
            item.assignedContractorName ||
            contractorDataStore.lookupCaption(item.assignedContractorID ?? "");
          var header = !!assignedContractorName?.length
            ? assignedContractorName
            : `${this.$t("scheduler.assigned-contractor-long")}`;
          otherCoordinators.push({
            header: header
          });
        }
      }

      var selectableCoordinators = otherCoordinators.concat(coordinatorsForContractor);

      var unselectableCoordinators = [] as any[];
      if (!!item.coordinatorID) {
        var currentCoordinator = selectableCoordinators.find(
          x => (x as PersonWithName)?.id == item.coordinatorID
        );
        if (!currentCoordinator) {
          unselectableCoordinators.push({
            divider: true
          });
          unselectableCoordinators.push({
            header: `${this.$t("scheduler.currently-assigned")}`
          });

          let unselectableCoordinator = {
            id: item.coordinatorID,
            name: item.coordinatorName || personDataStore.lookupCaption(item.coordinatorID),
            disabled: true
          } as PersonWithName & { disabled: boolean };
          unselectableCoordinators.push(unselectableCoordinator);
        }
      }

      return selectableCoordinators.concat(unselectableCoordinators);
    },

    getGeneralForemenForContractor(contractorID: string) {
      var generalForemenPeopleForContractor = SortItemsWithName(
        this.allGeneralForemen.filter(
          x =>
            (this.curUserCanViewAllContractors && !x.contractorID) || x.contractorID == contractorID
        )
      );
      return generalForemenPeopleForContractor;
    },

    getGeneralForemenForWorkOrder(item: WorkOrderWithExtraDetails) {
      var contractorID = item.assignedContractorID;
      var selectableGeneralForemen = !contractorID
        ? []
        : this.getGeneralForemenForContractor(contractorID);

      var unselectableGeneralForemen = [] as any[];
      if (!!item.generalForemanID) {
        var currentGeneralForeman = selectableGeneralForemen.find(
          x => (x as PersonWithName)?.id == item.generalForemanID
        );
        if (!currentGeneralForeman) {
          unselectableGeneralForemen.push({
            divider: true
          });
          unselectableGeneralForemen.push({
            header: `${this.$t("scheduler.currently-assigned")}`
          });

          let unselectableGeneralForeman = {
            id: item.generalForemanID,
            name: item.generalForemanName || personDataStore.lookupCaption(item.generalForemanID),
            disabled: true
          } as PersonWithName & { disabled: boolean };
          unselectableGeneralForemen.push(unselectableGeneralForeman);
        }
      }

      return selectableGeneralForemen.concat(unselectableGeneralForemen);
    },

    getForemenForContractor(contractorID: string) {
      var foremenPeopleForContractor = SortItemsWithName(
        this.allForemen.filter(
          x =>
            (this.curUserCanViewAllContractors && !x.contractorID) || x.contractorID == contractorID
        )
      );
      return foremenPeopleForContractor;
    },

    getForemenForWorkOrder(item: WorkOrderWithExtraDetails) {
      var contractorID = item.assignedContractorID;
      var selectableForemen = !contractorID ? [] : this.getForemenForContractor(contractorID);

      var unselectableForemen = [] as any[];
      if (!!item.foremanID) {
        var currentForeman = selectableForemen.find(
          x => (x as PersonWithName)?.id == item.foremanID
        );
        if (!currentForeman) {
          unselectableForemen.push({
            divider: true
          });
          unselectableForemen.push({
            header: `${this.$t("scheduler.currently-assigned")}`
          });

          let unselectableForeman = {
            id: item.foremanID,
            name: item.foremanName || personDataStore.lookupCaption(item.foremanID),
            disabled: true
          } as PersonWithName & { disabled: boolean };
          unselectableForemen.push(unselectableForeman);
        }
      }

      return selectableForemen.concat(unselectableForemen);
    },

    async openWorkDetailsForScaffoldDialog(
      item: WorkOrderWithExtraDetails,
      startingDismantleWorkOrder: boolean
    ): Promise<boolean> {
      var activeWorkOrders = [] as WorkOrderWithExtraDetails[];
      var workOrderIDs = item.scaffoldActiveWorkOrderIDs ?? [];
      workOrderIDs.forEach(otherWorkOrderId => {
        var loadedWorkOrder = this.allWorkOrders.find(x => x.id == otherWorkOrderId);
        if (!!loadedWorkOrder) {
          activeWorkOrders.push(loadedWorkOrder);
        }
      });
      // If the current item isn't included, that means there's an active request instead
      // However we still include it so that something shows.
      // The dialog will replace it after loading the data anyway
      if (!workOrderIDs.includes(item.id!)) activeWorkOrders.push(item);

      var convertedWorkOrders = activeWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));
      let result = await openActiveWorkForScaffoldDialog(
        item.scaffoldNumber,
        convertedWorkOrders,
        item.id,
        startingDismantleWorkOrder,
        false, // This is never approving a request
        false
      );
      if (!startingDismantleWorkOrder && result) {
        await this.reloadTableData();
      }
      return result;
    },

    calcWidths() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    }
  },

  mounted: function mounted() {
    // Listen to the "resize" even for the browser so we always know the width and height and can use that
    // knowledge for various responsive layout reasons.
    // Also we will additionally track the ScrollY offset for layout and placement related purposes.
    window.addEventListener("resize", this.calcWidths);
    //Grab the original width, height.
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
    window.removeEventListener("resize", this.calcWidths);
  }
});

