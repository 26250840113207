import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import {
  scaffoldService,
  ScaffoldStatuses,
  Tag,
  ProjectLocation,
  projectLocationService,
  contractorService,
  ContractorWithTags
} from "../services";
import userAccess from "../dataMixins/userAccess";
import { filterByTags } from "../services/taggableItems";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { valueInArray } from "@fd/lib/client-util/array";
import SPScaffoldsList, { FormattedScaffoldForList } from "./components/SP.ScaffoldsList.vue";
import tabbedView, { PageTab } from "../../../lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import SPScaffoldLocations, {
  ScaffoldWithLocation
} from "./components/data/scaffolds/SP.ScaffoldLocations.vue";
import { VDataTable } from "../../../lib/vue/types";
import locationDataStore from "../store/referenceData/projectLocation";
import { formatScaffoldTagNumber } from "../utils/scaffold";

type ScaffoldForScreen = FormattedScaffoldForList &
  ScaffoldWithLocation & {
    scaffoldContractorID: string | null | undefined;
  };

type FilteringContext = {
  dismantled: boolean;
  standing: boolean;
};

export default FDVue.extend({
  name: "fd-scaffolds-list",

  mixins: [userAccess, tabbedView, archivedDataList],

  directives: {},

  components: {
    "sp-scaffolds-list": SPScaffoldsList,
    "sp-scaffold-locations": SPScaffoldLocations
  },

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      firstTabKey: `1`,
      listTab: new PageTab({
        nameKey: "scaffolds.list.tabs.list",
        key: `1`,
        visible: true
      }),
      mapTab: new PageTab({
        nameKey: "scaffolds.list.tabs.map",
        key: `2`,
        visible: true
      }),

      allContractors: [] as ContractorWithTags[],
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      allScaffolds: [] as ScaffoldForScreen[]
    };
  },

  computed: {
    enableScaffoldLocation(): boolean {
      return this.$store.state.curEnvironment?.enableScaffoldLocation ?? false;
    },
    showScaffoldsOnMap(): boolean {
      return (
        (!!this.selectedAreaIDs?.length && !!this.selectedSubAreaIDs?.length) ||
        (this.tablesearch?.length ?? 0) > 2
      );
    },
    filterContext: {
      get(): FilteringContext {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    standingContextSelected: {
      get(): boolean {
        return this.filterContext.standing;
      },
      set(val: boolean) {
        var context = this.filterContext;
        context.standing = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },

    dismantledContextSelected: {
      get(): boolean {
        return this.filterContext.dismantled;
      },
      set(val: boolean) {
        var context = this.filterContext;
        context.dismantled = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },

    canViewContractorFilter(): boolean {
      let allSelectableContractorIDs = this.allScaffolds.map(x => x.scaffoldContractorID);
      let distinctSelectableContractorID = [...new Set(allSelectableContractorIDs)];
      return distinctSelectableContractorID.length > 0;
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    selectableAreas(): any[] {
      let selectableAreaIDs = this.allScaffolds.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => selectableAreaIDs.includes(x.id!));
    },

    selectedAreaIDs: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering;
      },
      set(val: string[] | undefined) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);

        if (!this.selectedSubAreaIDs?.length) return;

        if (!val?.length) {
          this.selectedSubAreaIDs = [];
        } else {
          let selectableSubAreas = this.allSubAreas.filter(x =>
            valueInArray(x.parentLocationID, val)
          );
          let selectableSubAreaIDs = selectableSubAreas.map(x => x.id!);
          this.selectedSubAreaIDs = this.selectedSubAreaIDs.filter(x =>
            valueInArray(x, selectableSubAreaIDs)
          );
        }
      }
    },

    selectableSubAreas(): any[] {
      let selectableSubAreaIDs = this.allScaffolds
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreaIDs;
      return this.allSubAreas.filter(
        x =>
          selectableSubAreaIDs.includes(x.id!) &&
          !!selectedAreaIDs.length &&
          selectedAreaIDs.includes(x.parentLocationID)
      );
    },

    selectedSubAreaIDs: {
      get(): string[] | undefined {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering;
      },
      set(val: string[] | undefined) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectedContractorIDs: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    selectableContractors(): any[] {
      let selectableContractorIDs = this.allScaffolds
        .filter(x => !!x.scaffoldContractorID)
        .map(x => x.scaffoldContractorID!);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return this.allContractors.filter(x => selectableContractorIDs.includes(x.id!));
    },

    statusItems(): { value: number; text: string }[] {
      var values = Object.keys(ScaffoldStatuses);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: ScaffoldStatuses[x]
        };
      });
      return items;
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allScaffolds);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },
    standingCount(): number {
      return this.allScaffolds?.filter(x => x.scaffoldStatus == ScaffoldStatuses.Erected).length;
    },
    dismantledCount(): number {
      return this.allScaffolds?.filter(
        x =>
          x.scaffoldStatus == ScaffoldStatuses.Dismantled ||
          x.scaffoldStatus == ScaffoldStatuses.Cancelled
      ).length;
    },
    scaffolds(): ScaffoldForScreen[] {
      var selectedScaffolds = this.allScaffolds;

      selectedScaffolds = selectedScaffolds.filter(
        x =>
          (this.standingContextSelected && x.scaffoldStatus == ScaffoldStatuses.Erected) ||
          (this.dismantledContextSelected &&
            (x.scaffoldStatus == ScaffoldStatuses.Dismantled ||
              x.scaffoldStatus == ScaffoldStatuses.Cancelled))
      );

      if (this.selectedContractorIDs.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.scaffoldContractorID, this.selectedContractorIDs)
        );
      }
      if (this.selectedAreaIDs.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.areaID, this.selectedAreaIDs)
        );
      }
      if (!!this.selectedSubAreaIDs?.length) {
        selectedScaffolds = selectedScaffolds.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreaIDs)
        );
      }

      if (!!this.tablesearch?.length) {
        let dataTable = (this.$refs.scaffoldslist as any)?.datatable as VDataTable;
        if (!!dataTable) {
          selectedScaffolds = dataTable.customFilterWithColumns(
            selectedScaffolds,
            this.tablesearch
          );
        }
      }

      selectedScaffolds = filterByTags(this.tagsSelectedForFiltering, selectedScaffolds);

      return selectedScaffolds;
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadInitialData() {
      await this.loadData();
      if (this.standingContextSelected && this.standingCount == 0) {
        this.standingContextSelected = false;
      }
      if (this.dismantledContextSelected && this.dismantledCount == 0) {
        this.dismantledContextSelected = false;
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      let scaffolds = await scaffoldService.getAllScaffoldsOptimized(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );

      // Pre-lookup all location info so that it's loaded for if/when the user opens a popup
      let scaffoldIDs = [...new Set(scaffolds.map(x => x.id!))];
      scaffoldIDs.forEach(x => locationDataStore.lookupCaption(x));

      this.allScaffolds = scaffolds.map(x => {
        return {
          ...x,

          id: x.id!,
          areaID: x.areaID ?? null,
          subAreaID: x.subAreaID ?? null,
          archived: !!x.archivedDate,
          scaffoldNumber: x.internalNumber ?? 0,
          // Include both the padded and unpadded tag number for search purposes (T-01001 and T-1001 are both searchable)
          prefixedTagNumber: `${formatScaffoldTagNumber(
            x.internalNumber
          )} ${formatScaffoldTagNumber(x.internalNumber, false)}`,
          latitude: x.latitude ?? null,
          longitude: x.longitude ?? null,
          scaffoldStatus: x.scaffoldStatus!,

          scaffoldStatusName: this.$t(`scaffolds.status.${x.scaffoldStatus}`),
          workPackageNames: x.workPackages?.map(x => (x.name ?? "") + " | " + (x.activityID ?? "")),
          daysStanding: !!x.actualErectDate
            ? Math.round(
                ((!!x.actualDismantleDate ? x.actualDismantleDate : new Date()).getTime() -
                  x.actualErectDate.getTime()) /
                  86400000
              )
            : undefined,
          areaName: this.allAreas.find(a => a.id == x.areaID)?.name,
          subAreaName: this.allSubAreas.find(a => a.id == x.subAreaID)?.name,
          scaffoldContractorID: x.lastWorkOrderContractorID ?? x.contractorID,
          scaffoldContractorName: this.allContractors.find(
            a => a.id == (x.lastWorkOrderContractorID ?? x.contractorID)
          )?.name,

          plannedErect: !!x.plannedErectDate ? this.$format.date(x.plannedErectDate) : undefined,
          actualErect: !!x.actualErectDate ? this.$format.date(x.actualErectDate) : undefined,
          plannedDismantle: !!x.plannedDismantleDate
            ? this.$format.date(x.plannedDismantleDate)
            : undefined,
          actualDismantle: !!x.actualDismantleDate
            ? this.$format.date(x.actualDismantleDate)
            : undefined
        };
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      this.allContractors = await contractorService.getAll(false, null, null);
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeCreate: async function() {},

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffolds",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: { standing: true, dismantled: false } as FilteringContext,
      selectedTab: this.firstTabKey
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffolds.list.title"),
      to: "/scaffolds",
      resetHistory: true
    });
    try {
      this.processing = true;
      await Promise.all([this.loadAreas(), this.loadSubAreas(), this.loadContractors()]);
      this.processing = true;
      await this.loadInitialData();
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

