import { ContractorPurchaseOrder, contractorPurchaseOrderService } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that Contractor's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that Contractor's name field is undefined-able

const contractorPurchaseOrderDataStore = createReferenceDataStore<ContractorPurchaseOrder & Record>(
  "contractorPurchaseOrder",
  contractorPurchaseOrderService as BasicReferenceDataCrudService<ContractorPurchaseOrder & Record>,
  x => x.purchaseOrderNumber!
);
export default contractorPurchaseOrderDataStore;

